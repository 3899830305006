import React, {forwardRef, useCallback, useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import {Alert, Button, Col, Modal, Row} from "react-bootstrap";
import {notify} from "react-notify-bootstrap";
import TechnologyCapacity from "./TechnologyCapacity";
import CompanyCapacity from "./CompanyCapacity";
import PivotTable from "../common/PivotTable";
import {ArrowsMove} from "react-bootstrap-icons";
import BasicTable from "../common/BasicTable";
import {buildRangeParameters, getDomainByLocation} from "../commons";
import DatePicker from "react-datepicker";

const ResponsiveReactGridLayout = require('react-grid-layout');


const companyColumns = [
    {
        name: "Name",
        selector: row => row.name
    },
    {
        name: "Code",
        selector: row => row.code
    },
    {
        name: "Storage",
        selector: row => row.storage

    },
    {
        name: "Strategy",
        selector: row => row.strategy
    }
];

const interconnectionsColumns = [
    {
        name: "Name",
        selector: row => row.name
    },
    {
        name: "Capacity (MW)",
        selector: row => row.capacity
    },
    {
        name: "Loss Factor (%)",
        selector: row => row.loss_factor
    }
];

const renewablesColumns = [
    {
        name: "Name",
        selector: row => row.name

    },
    {
        name: "Type",
        selector: row => row.type

    },
    {
        name: "Availability (%)",
        selector: row => row.unavailability
    },
    {
        name: "Power",
        selector: row => row.power
    },
    {
        name: "O&M (€/MWh)",
        selector: row => row.cop
    },
    {
        name: "FiT",
        selector: row => row.fit
    },
    {
        name: "Ownership",
        selector: row => row.company_properties.map(cp=>cp.company + "(" + cp.percentage + "%)").join(",")
    }
];

const nuclearColumns = [
    {
        name: "Name",
        selector: row => row.name
    },
    {
        name: "Availability (%)",
        selector: row => row.unavailability
    },
    {
        name: "Power",
        selector: row => row.power
    },
    {
        name: "O&M (€/MWh)",
        selector: row => row.om
    },
    {
        name: "Ownership",
        selector: row => row.company_properties.map(cp=>cp.company + "(" + cp.percentage + "%)").join(",")
    }
];

const ccgtColumns = [
    {
        name: "Name",
        selector: row => row.name
    },
    {
        name: "Availability (%)",
        selector: row => row.unavailability
    },
    {
        name: "Power",
        selector: row => row.power
    },
    {
        name: "Efficiency (LHV)",
        selector: row => row.pci
    },
    {
        name: "O&M (€/MWh)",
        selector: row => row.om
    },
    {
        name: "Ownership",
        selector: row => row.company_properties.map(cp=>cp.company + "(" + cp.percentage + "%)").join(",")
    }
];

const hydraulicColumns = [
    {
        name: "Name",
        selector: row => row.name
    },
    {
        name: "Availability (%)",
        selector: row => row.unavailability
    },
    {
        name: "Power",
        selector: row => row.power
    },
    {
        name: "O&M (€/MWh)",
        selector: row => row.om
    },
    {
        name: "Ownership",
        selector: row => row.company_properties.map(cp=>cp.company + "(" + cp.percentage + "%)").join(",")
    }
];

const storageColumns = [
    {
        name: "Name",
        selector: row => row.name
    },
    {
        name: "Availability (%)",
        selector: row => row.unavailability
    },
    {
        name: "Power",
        selector: row => row.power
    },
    {
        name: "O&M (€/MWh)",
        selector: row => row.om
    },
    {
        name: "Ownership",
        selector: row => row.company_properties.map(cp=>cp.company + "(" + cp.percentage + "%)").join(",")
    }
];

const carbonColumns = [
    {
        name: "Name",
        selector: row => row.name
    },
    {
        name: "Availability (%)",
        selector: row => row.unavailability
    },
    {
        name: "Power",
        selector: row => row.power
    },
    {
        name: "PCI",
        selector: row => row.pci
    },
    {
        name: "Ownership",
        selector: row => row.company_properties.map(cp=>cp.company + "(" + cp.percentage + "%)").join(",")
    }
];

const detailLayout = [
    {i: 'technologyCapacity', x: 0, y: 0, w: 24, h: 15},
    {i: 'firmCapacity', x: 0, y: 15, w: 24, h: 15},
    {i: 'pivotTable',   x: 0, y: 30, w: 24, h: 15},
    {i: 'firms',        x: 0, y: 45, w: 24, h: 8},
    {i: 'renewable',   x: 0, y: 50, w: 12, h: 8},
    {i: 'nuclear',      x: 12, y: 50, w: 12, h: 8},
    {i: 'coal',         x: 0, y: 58, w: 12, h: 8},
    {i: 'hydraulic',    x: 12, y: 58, w: 12, h: 8},
    {i: 'storage',      x: 0, y: 66, w: 12, h: 8},
    {i: 'interconnections', x: 12, y: 66, w: 12, h: 8},
    {i: 'ccgt',         x: 0, y: 74, w: 24, h: 8}
];

const minLayout = [
    {i: 'technologyCapacity', x: 0, y: 0, w: 24, h: 14},
    {i: 'firmCapacity', x: 0, y: 14, w: 24, h: 14},
    {i: 'pivotTable', x: 0, y: 28, w: 24, h: 14}
];


const MarketStructureLayout = ({ marketId, detail, onDeleteMarketStructure, onLoadAll }) => {
    const [show, setShow] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [interconnections, setInterconnections] = useState([]);
    const [renewables, setRenewables] = useState([]);
    const [nuclear, setNuclear] = useState([]);
    const [ccgt, setCcgt] = useState([]);
    const [carbon, setCarbon] = useState([]);
    const [hydraulic, setHydraulic] = useState([]);
    const [storage, setStorage] = useState([]);
    const [marketStructureInfo, setMarketStructureInfo] = useState(null);
    const [marketStats, setMarketStats] = useState([]);
    const [marketStructureIdToDelete, setMarketStructureIdToDelete] = useState(null);
    const [layout, setLayout] = useState(minLayout);
    const [resultsFrom, setResultsFrom] = useState(null);

    const sendNotification = (innerHTML, variant) => {
        notify({ text: innerHTML, variant: variant });
    };

    const deleteMarketStructure = useCallback(
        event => {
            event.preventDefault();
            setMarketStructureIdToDelete(event.target.id);
            setShow(true);
        },
        []
    );

    const handleClose = () => setShow(false);

    const confirmDeleteMarketStructure = () =>{
        trackPromise(
            fetch('/m40alasocho/market/structure/' + marketStructureIdToDelete, { method: 'DELETE' }))
            .then(res => res.json()).then(data => {
                setMarketStructureInfo(null);
                setMarketStructureIdToDelete(null);
                handleClose();
                onDeleteMarketStructure(data.list);
                sendNotification('Estructura de Mercado eliminada', "success");
            });
    };

    useEffect(() => {
        let url = '/m40alasocho/market/structure/' + marketId;
        if(detail){
            url += '/all'
        }
        if(resultsFrom){
            url += '?' + buildRangeParameters([resultsFrom, undefined]);
        }
        trackPromise(
            fetch(url))
            .then(res => res.json()).then(data => {
                setCompanies(data.companies);
                setMarketStructureInfo(data.market_structure);
                setMarketStats(data.market_stats);
                if(detail) {
                    setInterconnections(data.interconnections);
                    setRenewables(data.renewables);
                    setNuclear(data.nuclear);
                    setCcgt(data.ccgt);
                    setCarbon(data.carbon);
                    setHydraulic(data.hydraulic);
                    setStorage(data.storage);
                    setLayout(detailLayout)
                }
                sendNotification('Estructura de Mercado cargada', "success");
        });
    }, [marketId, detail, resultsFrom]);

    useEffect(() => {
        setLayout(detail ? detailLayout : minLayout)
    }, [detail]);

    const domain = getDomainByLocation();
    const downloadLink = marketStructureInfo != null ? (domain + '/m40alasocho/market/structure/' + marketStructureInfo.id + '/download')
        : null;

    const PeriodRangeDateButton = forwardRef(({ value, onClick, placeholder }, ref) => (
        <button className="btn btn-sm btn-outline-info form-control" onClick={onClick} ref={ref}>
            {value ? value : placeholder}
        </button>
    ));

    const updateRangeFrom = (from)=>{
        setResultsFrom(from);
    };

    const legend = marketStructureInfo != null ? (<Row>
            <Col sm={3}>
                <Row>
                    <Col sm={6}>
                        <DatePicker selected={resultsFrom}
                                    onChange={(date) => updateRangeFrom(date)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={'Select a Date'}
                                    closeOnScroll={true}
                                    showYearDropdown={true}
                                    customInput={<PeriodRangeDateButton />}
                        />
                    </Col>
                </Row>
            </Col>
            <Col sm={9}>
                {marketStructureInfo.id} / {marketStructureInfo.filename} / {marketStructureInfo.import_date.join(" ")}
                    <button type="button" className="btn btn-sm btn-outline-danger float-right" style={{marginRight:"5px"}}
                            disabled={marketStructureInfo.simulations > 0}
                            onClick={deleteMarketStructure} id={marketStructureInfo.id}>Delete</button>
                    <a target="_blank" href={downloadLink} rel="noreferrer">
                        <button type="button" className="btn btn-sm btn-outline-primary float-right" style={{marginRight:"5px"}}>Download</button>
                    </a>
                    <button type="button" className="btn btn-sm btn-outline-primary float-right"  style={{marginRight:"5px"}} onClick={()=>{
                        onLoadAll({
                            id: marketStructureInfo.id,
                            detail: true
                        });
                    }} id={marketStructureInfo.id}>Load All Data</button>
            </Col>
        </Row>) : null;

    const layoutGrid = detail ? (
                <ResponsiveReactGridLayout className="market-structure-layout" layout={layout}
                                           width={1300}
                                           draggableHandle=".draggableHandler">
                    <div key="technologyCapacity" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                        <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                        <div style={{overflow:"auto", height: "100%"}}>
                            <TechnologyCapacity data={marketStats}/>
                        </div>
                    </div>
                    <div key="firmCapacity" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                        <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                        <div style={{overflow:"auto", height: "100%"}}>
                            <CompanyCapacity data={marketStats}/>
                        </div>
                    </div>
                    <div key="pivotTable" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                        <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                        <div style={{overflow:"auto", height: "100%"}}>
                            <PivotTable data={marketStats}/>
                        </div>
                    </div>
                    <div key="firms" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                        <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                        <div style={{overflow:"auto", height: "100%"}}>
                            <BasicTable title="Firms" data={companies} columns={companyColumns}/>
                        </div>
                    </div>
                    <div key="interconnections" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                        <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                        <div style={{overflow:"auto", height: "100%"}}>
                            <BasicTable title="Interconnections" data={interconnections} columns={interconnectionsColumns}/>
                        </div>
                    </div>
                    <div key="renewable" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                        <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                        <div style={{overflow:"auto", height: "100%"}}>
                            <BasicTable title="Renewables" data={renewables} columns={renewablesColumns}/>
                        </div>
                    </div>
                    <div key="nuclear" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                        <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                        <div style={{overflow:"auto", height: "100%"}}>
                            <BasicTable title="Nuclear" data={nuclear} columns={nuclearColumns}/>
                        </div>
                    </div>
                    <div key="ccgt" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                        <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                        <div style={{overflow:"auto", height: "100%"}}>
                            <BasicTable title="CCGTs" data={ccgt} columns={ccgtColumns}/>
                        </div>
                    </div>
                    <div key="coal" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                        <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                        <div style={{overflow:"auto", height: "100%"}}>
                            <BasicTable title="Coal" data={carbon} columns={carbonColumns}/>
                        </div>
                    </div>
                    <div key="hydraulic" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                        <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                        <div style={{overflow:"auto", height: "100%"}}>
                            <BasicTable title="Hydro" data={hydraulic} columns={hydraulicColumns}/>
                        </div>
                    </div>
                    <div key="storage" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                        <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                        <div style={{overflow:"auto", height: "100%"}}>
                            <BasicTable title="Storage" data={storage} columns={storageColumns}/>
                        </div>
                    </div>
                </ResponsiveReactGridLayout>
        ) :
        layout.length > 0 ? (
            <ResponsiveReactGridLayout className="market-structure-layout" layout={layout} cols={24}
                                       rowHeight={31} width={1300} draggableHandle=".draggableHandler">
                <div key="technologyCapacity" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                    <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                    <div style={{overflow:"auto", height: "100%"}}>
                        <TechnologyCapacity data={marketStats}/>
                    </div>
                </div>
                <div key="firmCapacity" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                    <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                    <div style={{overflow:"auto", height: "100%"}}>
                        <CompanyCapacity data={marketStats}/>
                    </div>
                </div>
                <div key="pivotTable" style={{border: "1px solid #E0E0E0", position: "relative"}}>
                    <ArrowsMove className="draggableHandler" style={{right: "2px", position: "absolute", top:"2px", zIndex:"999", cursor:"grab"}}/>
                    <div style={{overflow:"auto", height: "100%", marginBottom:"20px"}}>
                        <PivotTable data={marketStats}/>
                    </div>
                </div>
            </ResponsiveReactGridLayout>
        ) : null;

        
    return (
        <div style={{marginTop: "10px"}}>
          <Alert variant="primary">
              {legend}
          </Alert>
          {layoutGrid}
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Estructura de Mercado</Modal.Title>
                </Modal.Header>
                <Modal.Body>Quieres eliminar esta estructura de mercado?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={confirmDeleteMarketStructure}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
      </div>
  );
};

export default MarketStructureLayout;
