import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import React, {useCallback, useEffect, useState} from "react";
import {
    findColorByKey, getFormattedNumber,
    getFormattedValue,
    getLineVisibility,
    sendNotification
} from "../commons";
import {useCurrentPng} from "recharts-to-png";
import FileSaver from "file-saver";
import {FaImage} from "react-icons/fa";
import { MdRestore} from "react-icons/md";

function BarChartComponent({title, data, bars, xAxisKey, lineVisibilityDefault={}, yAxisUnit, yAxisRight, xAxisLabel, yAxisLabel}) {

    const [lineVisibility, setLineVisibility] = useState(lineVisibilityDefault);
    const [getPng, { ref }] = useCurrentPng();

    useEffect(()=>{
    }, [data]);


    function updateVisibility(event) {
        const lineStatus = event["dataKey"].trim() in lineVisibility ? lineVisibility[event["dataKey"].trim()] : null;
        const visibility = (lineStatus === "visible" || lineStatus == null) ? "hidden" : "visible";
        setLineVisibility({...lineVisibility, [event["dataKey"].trim()]: visibility});
        sendNotification(event["value"].trim() + " is " + visibility, "success");
    }

    const renderColorfulLegendText = (val, entry) => {
        return getLineVisibility(val, entry);
    };

    const getYAxisIdByName = (name) => {
        if(yAxisRight){
            if(name.startsWith(yAxisRight["label"])){
                return "right";
            }
        }
        return "left";
    }

    let barsLabels = [];
    Object.keys(bars).forEach(function(key, index) {
        barsLabels.push(<Bar key={key}
                             dataKey={key}
                             stackId="a"
                             visibility={key in lineVisibility ? lineVisibility[key] : "visible"}
                             fillOpacity={"0.6"}
                             fill={findColorByKey(key, index)}
                             name={bars[key]}
                             yAxisId={getYAxisIdByName(bars[key])}/>)
    });

    let filteredData = [];
    Object.keys(lineVisibility).forEach(k=>{
       if(lineVisibility[k] === "hidden"){
           filteredData = data.map(d=>{
               return { k, ...d};
           })
       }
    });
    filteredData = data.map(d=>{
        let filtered = {};
        Object.keys(d).forEach(k=>{
            if((k in lineVisibility && lineVisibility[k] === "visible") || !(k in lineVisibility)){
                filtered[k] = d[k];
            }
        });
        return filtered;
    });

    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
            // Download with FileSaver
            FileSaver.saveAs(png, title + '.png');
        }
    }, [getPng, title]);

    const iconsStyle = {
        marginLeft:"15px",
        cursor: "pointer"
    };

    let axisRight = null;
    if(yAxisRight){
        axisRight = <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" unit={yAxisRight["unit"]}></YAxis>
    }

    let yAxisProps = {};
    let xAxisProps = {};
    if(yAxisLabel){
        yAxisProps = {
            label: {
                value: yAxisLabel,
                position: "left"
            }
        }
    }
    if(xAxisLabel){
        xAxisProps = {
            label: {
                value: xAxisLabel,
                position: "bottom"
            }
        }
    }

    return (
        <div style={{
            width: "100%",
            height: "450px"
        }}>
            <h6>
                {title}
                <MdRestore style={iconsStyle} onClick={()=>setLineVisibility({})} title="Restore to initial state"/>
                <FaImage style={iconsStyle} onClick={()=>handleDownload()} title="Download chart image"/>
            </h6>
            <ResponsiveContainer>
                <BarChart data={filteredData} ref={ref} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={xAxisKey} angle="30"
                           dy={35} height={70}
                           {...xAxisProps}
                           interval={0} tickFormatter={(v)=>{
                        return v;
                    }}/>
                    <YAxis unit={yAxisUnit}
                           yAxisId={"left"}
                           {...yAxisProps}
                           tickFormatter={(value) => {
                               return getFormattedNumber(value);
                           }}/>
                    {axisRight}
                    <Tooltip
                        formatter={(value, name, props) => {
                            if(!props["unit"]){
                                props["unit"] = yAxisUnit;
                            }
                            if(yAxisRight && yAxisRight.keys.includes(name)){
                                props["unit"] = yAxisRight["unit"];
                            }
                        return [getFormattedValue(props, value), name];
                    }}/>
                    <Legend layout="horizontal" verticalAlign="top" align="center" height={36} onClick={(event)=>{
                        updateVisibility(event);
                    }}
                    wrapperStyle={{position: 'relative', marginTop: '10px'}}
                    formatter={renderColorfulLegendText}
                    />
                    {barsLabels}
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}
export default BarChartComponent;
