import {Button, Col, Container, Form, Modal, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import {FaUser, FaUserEdit} from "react-icons/fa";
import {GrCheckbox, GrCheckboxSelected, GrUserAdmin} from "react-icons/gr";

import {buildDateFromStr, sendNotification} from "../commons";

function getStatusClassByUser(user) {
    if (user.status === "PENDING")
        return "pending-user";
    else if(user.status === "ACTIVE" && user.dateTo !== '' && buildDateFromStr(user.dateTo) < new Date()){
        return "expired-user";
    }
    return "active-user";
}

function getStatusValueByUser(user) {
    if (user.status === "PENDING")
        return "PENDING VALIDATION";
    else if(user.status === "ACTIVE" && user.dateTo !== '' && buildDateFromStr(user.dateTo) < new Date()){
        return "EXPIRED PERIOD";
    }
    return "ACTIVE";
}

function Users() {

    const [users, setUsers] = useState([]);
    const [showUser, setShowUser] = useState(false);
    const [userEdit, setUserEdit] = useState(null);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/user/list'))
            .then(res => res.json()).then(data => {
            setUsers(data.users);
        });
    }, []);

    const editUser = (idx) =>{
        setUserEdit(users[idx]);
        setShowUser(true);
    };

    const saveUserInfo = () =>{
        trackPromise(
            fetch('/m40alasocho/user', {
                method: 'POST' ,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({...userEdit})
            }))
            .then(res => res.json()).then(data => {
            if(data["error"]){
                sendNotification(data["error"], "warning");
            }else {
                sendNotification("User updated", "success");
                setUsers(data.users);
                setUserEdit(null);
                setShowUser(false);
            }
        });
    };

    const handleClose = () => setShowUser(false);

    const userRows = users.map((u, idx)=>{
        const cellClass = getStatusClassByUser(u);
        const status = getStatusValueByUser(u);
        const userRole = u.role === "USER" ? <div>
            <FaUser/> {u.role}
        </div> : <div>
            <GrUserAdmin/> {u.role}
        </div>;

        const userInvestments = u.investments ? <div>
            <GrCheckboxSelected/>
        </div> : <div>
            <GrCheckbox/>
        </div>;


        return <tr key={idx}>
                    <td>{idx}</td>
                    <td>{u.name}</td>
                    <td>{u.surname}</td>
                    <td>{u.username}</td>
                    <td className={cellClass}>{status}</td>
                    <td>{userRole}</td>
                    <td>{userInvestments}</td>
                    <td>{u.dateFrom}</td>
                    <td>{u.dateTo}</td>
                    <td>
                        <Button variant="primary" size="sm" onClick={()=>editUser(idx)}>
                            <FaUserEdit/>
                        </Button>
                    </td>
                </tr>
    });

    const validateUserForm = () => {
        if(userEdit) {
            if (userEdit.username.length === 0 || userEdit.name.length === 0 || userEdit.surname.length === 0) {
                return false
            }
            if (userEdit.dateFrom === '' || userEdit.dateTo === '') {
                return false;
            }
            let fromDate = buildDateFromStr(userEdit.dateFrom);
            let toDate = buildDateFromStr(userEdit.dateTo);
            return fromDate < toDate;
        }
        return true;
    };

    const modalInfo = userEdit ? <Modal show={showUser} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="2">
                        Email
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="email" value={userEdit.username} readOnly/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                        Name
                    </Form.Label>
                    <Col sm="5">
                        <Form.Control value={userEdit.name} onChange={event=>{
                            setUserEdit({...userEdit, name: event.target.value});
                        }}/>
                    </Col>
                    <Form.Label column sm="2">
                        Investments
                    </Form.Label>
                    <Col sm="3">
                        <Form.Control as="select" aria-label="Investments" size="sm" value={userEdit.investments} onChange={event=>{
                            setUserEdit({...userEdit, investments: event.target.value});
                        }}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                        Surname
                    </Form.Label>
                    <Col sm="5">
                        <Form.Control value={userEdit.surname} onChange={event=>{
                            setUserEdit({...userEdit, surname: event.target.value});
                        }}/>
                    </Col>
                    <Form.Label column sm="2">
                        Status
                    </Form.Label>
                    <Col sm="3">
                        <Form.Control as="select" aria-label="Status" size="sm" value={userEdit.status} onChange={event=>{
                            setUserEdit({...userEdit, status: event.target.value});
                        }}>
                            <option value="PENDING">Pending Validation</option>
                            <option value="ACTIVE">Active</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                        Period
                    </Form.Label>
                    <Col sm="5">
                        <Form.Control type="date" aria-label="Date From" size="sm" value={userEdit.dateFrom} onChange={event=>{
                            setUserEdit({...userEdit, dateFrom: event.target.value});
                        }}/>
                    </Col>
                    <Col sm="5">
                        <Form.Control type="date" aria-label="Date To" size="sm" value={userEdit.dateTo} onChange={event=>{
                            setUserEdit({...userEdit, dateTo: event.target.value});
                        }}/>
                    </Col>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Cancelar
            </Button>
            <Button variant="primary" onClick={saveUserInfo} disabled={!validateUserForm()}>
                Save
            </Button>
        </Modal.Footer>
    </Modal> : null;

    return (
        <Container>
            <div>
                <Table bordered>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Role</th>
                        <th>Investments</th>
                        <th>Active From</th>
                        <th>Active To</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        {userRows}
                    </tbody>
                </Table>
                {modalInfo}
            </div>
        </Container>
    );
}

export default Users;
