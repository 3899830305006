import React, {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import LineChartWrapper from "../../common/LineChartWrapper";
import {addTotals, buildColumnsFromData, divideUnitsBy} from "../../commons";
import BasicTable from "../../common/BasicTable";
import ChartTableToggle from "../../common/ChartTableToggle";
import {Col, Row} from "react-bootstrap";

function InvestorCashFlowView({
                              viewName,
                              typeName,
                              aggregation,
                              simulationId,
                              simulationInitialValues,
                              simulation
                          }){

    const [investorData, setInvestorData] = useState({});
    const [tabMode, setTabMode] = useState("charts");

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/investment/' + simulationId + '/settings/' + simulationInitialValues + '/results/'+typeName+'?'
                + 'aggregation='+ aggregation))
            .then(res => res.json()).then(data => {
                if(data["data"].length === 1) {
                    setInvestorData({...JSON.parse(data["data"][0]["data"])});
                }
        });

    }, [simulationId, aggregation, typeName, simulationInitialValues, simulation.configuration.plants]);

    const yearFrom = simulation.configuration.timePeriod['yearFrom'];
    const yearTo = simulation.configuration.timePeriod['yearTo'];
    let data = [];
    for (let i = yearFrom, indexCounter=0; i <= yearTo; i++, indexCounter++){
        let out = {
            time: i,
        }
        Object.keys(investorData).forEach(k => {
            if(k.startsWith("Total_")) {
                out[k.replace("Total_", "")] = investorData[k][indexCounter];
            }
        })
        data.push(out);
    }

    const tableData = [...data].map(d=>({...d}));


    const dataByMode = tabMode === "charts" ? (
        <LineChartWrapper data={divideUnitsBy(data, 1000000)}
                          title={viewName}
                          brushKey="time"
                          xAxisKey="time"
                          aggregation={"YearInt"}
                          yAxisUnit="M€"/>
    ) : (
        <div style={{marginTop: "15px"}}>
            <BasicTable title={viewName} data={addTotals(tableData)} columns={buildColumnsFromData(tableData)} options={{
                fixedHeaderScrollHeight: "350px"
            }}/>;
        </div>
    );

    return Object.keys(investorData).length > 0 ? (
        <div className="recharts-component">
            <ChartTableToggle mode={tabMode} updateTabMode={setTabMode} updateSelectedTab={()=>{}}/>
            <Row>
                <Col sm={12}>
                    {dataByMode}
                </Col>
            </Row>
        </div>
    ) : <h3>Loading...</h3>
}

export default InvestorCashFlowView;