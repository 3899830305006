import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import './Login.css';
import { Button, Container, Row } from "react-bootstrap";
import { buildDateFromStr, sendNotification } from "../commons";

import bg from "../../img/Background login.png";
import energeia from "../../img/Logo ENERGEIA big.png"
import login_logo from "../../img/Icon login.png"
import user_icon from "../../img/Icon user.png"
import password_icon from "../../img/Icono password.png"
import {loginRequest, msalConfig} from "./Auth";
import * as Msal from "msal";
import {SiMicrosoftazure} from "react-icons/si";

async function loginUser(credentials) {
    return fetch('/m40alasocho/user/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

async function createUser(credentials) {
    return fetch('/m40alasocho/user/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

function checkSSOUser(data){
    return fetch('/m40alasocho/user/sso', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

const Login = ({ setToken }) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [userInfo, setUserInfo] = useState({
        name: "",
        surname: ""
    });
    const [mode, setMode] = useState("login");

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    // function validateCreateForm() {
    //     return email.length > 0 && password.length > 0 && password === passwordRepeat && userInfo.name.length > 0
    //         && userInfo.surname.length > 0;
    // }

    const userPeriodExpired = (user) => {
        if (user["dateTo"] !== '') {
            let dateTo = buildDateFromStr(user["dateTo"]);
            return dateTo < new Date();
        }
        return false;
    };

    async function handleSubmit(event) {
        event.preventDefault();
        if (mode === "login") {
            const data = await loginUser({
                email,
                password
            });
            if (data["error"]) {
                sendNotification(data["error"], "warning");
            } else {
                const userName = data["user"]["name"] + " " + data["user"]["surname"];
                if (data["user"]["status"] === "PENDING") {
                    sendNotification(userName + " is not active. Please ask site administrator", "warning");
                } else if (userPeriodExpired(data["user"])) {
                    sendNotification(userName + " has expired his use period. Please ask site administrator", "warning");
                } else {
                    setToken({
                        "key": data["token"],
                        "user": data["user"]
                    });
                }
            }
        } else {
            const data = await createUser({
                email,
                password,
                userInfo
            });
            if (data["error"]) {
                sendNotification(data["error"], "warning");
            } else {
                sendNotification("User created. Please ask your administrator to activate the account", "success");
            }
        }
    }

    useEffect(() => {
        document.body.style.backgroundImage = `url('${bg}')`;
    }, []);

    msalConfig.auth.clientId = "9daf8833-aac0-4a4d-b085-4a621eb8bac7";//f180a437-18e2-4ffd-9bd9-d6537ba45ecd;
    msalConfig.auth.authority= "https://login.microsoftonline.com/831f8b7b-7bbc-4d34-a62b-7baf9792d24a"; //2735179a-eb9f-4eb0-bb4e-919749120851

    async function loginBySSO(){
        const msalAuth = new Msal.UserAgentApplication(msalConfig);
        msalAuth.loginPopup(loginRequest)
            .then(loginResponse => {
                if (msalAuth.getAccount()) {
                    const account = msalAuth.getAccount()
                    const userInfo = {
                        name: account.name,
                        username: account.userName
                    }
                    checkSSOUser(userInfo)
                        .then((response)=>{
                            if(response.ok){
                                return response.json();
                            }
                        }).then((userData)=>{
                            const userName = userData.user.name;
                            if (userData.user.status === "PENDING") {
                                sendNotification(userName + " is not active. Please ask site administrator", "warning");
                            } else if (userPeriodExpired(userData.user)) {
                                sendNotification(userName + " has expired his use period. Please ask site administrator", "warning");
                            } else {
                                setToken({
                                    "key": msalAuth.getAccount().idToken,
                                    "user": userData.user
                                });
                            }
                        });
                }
            }).catch(error => {
            console.error(error);
        });
    }

    const formBody = mode === "login" ? <div>
        <div className="input-group form-group">
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <img src={user_icon} alt="Login"></img>
                </span>
            </div>
            <input className="form-control"
                   type="email"
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
                   placeholder="Username"/>
        </div>
        <div className="input-group form-group">
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <img src={password_icon} alt="Password"></img>
                </span>
            </div>
            <input type="password" className="form-control"
                   value={password}
                   onChange={(e) => setPassword(e.target.value)}
                   placeholder="Password"/>
        </div>
        <div className="input-group form-group">
            <Button type="submit" disabled={!validateForm()} block>
                LOGIN
            </Button>
        </div>
        <div className={"input-group"}>
            <span className="text-center" style={{marginLeft:"45%", marginBottom: "15px"}}>or</span>
        </div>
        <div className="input-group text-center">
            <Button className={"btn-block"} onClick={()=>{
                loginBySSO();
            }}
                    type={"button"}
            >
                <SiMicrosoftazure /> Sign in with azure
            </Button>
        </div>
    </div> : <div>
        <div className="input-group form-group">
        <div className="input-group-prepend">
                <span className="input-group-text">
                    <img src={user_icon} alt="Login"></img>
                </span>
            </div>
            <input className="form-control"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email" />
        </div>
        <div className="input-group form-group">
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <img src={user_icon} alt="Login"></img>
                </span>
            </div>
            <input type="text" className="form-control"
                value={userInfo.name}
                onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
                placeholder="Name" />
        </div>
        <div className="input-group form-group">
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <img src={user_icon} alt="Login"></img>
                </span>
            </div>
            <input type="text" className="form-control"
                value={userInfo.surname}
                onChange={(e) => setUserInfo({ ...userInfo, surname: e.target.value })}
                placeholder="Surname" />
        </div>
        <div className="input-group form-group">
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <img src={password_icon} alt="Password"></img>
                </span>
            </div>
            <input type="password" className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password" />
        </div>
        <div className="input-group form-group">
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <img src={password_icon} alt="Password"></img>
                </span>
            </div>
            <input type="password" className="form-control"
                value={passwordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
                placeholder="Repeat password" />
        </div>
        <div className="input-group form-group">
            <Button type="submit" disabled={!validateForm()} block>
                CREATE
            </Button>
        </div>
    </div>;

    const toggleMode = () => {
        setMode(mode === "login" ? "signup" : "login");
    };

    const signUpButton = mode === "login" ? <div className="d-flex justify-content-left links">
        Don't have an account? <Button variant="link" size="sm" onClick={() => toggleMode()} style={{ paddingTop: 0, fontSize: '16px'}}>Sign up</Button>
    </div> : <div className="d-flex justify-content-left links">
        Have an account? <Button variant="link" size="sm" onClick={() => toggleMode()} style={{ paddingTop: 0, fontSize: '16px' }}>Login</Button>
    </div>;

    return (
        <Container className="Login container-login" direction="vertical">
            <Row>
                <div className="d-flex justify-content-center">
                    <img src={energeia} alt="Energeia" width="500px"></img>
                </div>
            </Row>

            <Row className="login-form">
                <Form onSubmit={handleSubmit}>
                    <div className="login-form">
                        <img className="login-logo" src={login_logo} alt="Login"></img>
                        {formBody}
                        {signUpButton}
                        {
                            mode === "login" && (
                                <div className="d-flex justify-content-left links">
                                    {/*eslint-disable-next-line*/}
                                    <a href="#">Forgot your password?</a>
                                </div>)
                        }

                    </div>
                </Form>
            </Row>

        </Container>
    );
};

export default Login;