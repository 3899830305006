import React, {useEffect, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {
    addTotals,
    buildColumnsFromData,
    buildComparativeData,
    buildRangeParameters,
    convertToMomentTz,
    filterByStrategy,
    findColorByKey,
    getDateFormatByAggregation,
    sendNotification
} from "../commons";
import {trackPromise} from "react-promise-tracker";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import AreaChartWrapper from "../common/AreaChartWrapper";
import ChartTableToggle from "../common/ChartTableToggle";
import TablePagination from "../common/TablePagination";
import BasicTable from "../common/BasicTable";
import LineChartWrapper from "../common/LineChartWrapper";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

function clearNotRequiredKeys(data) {
    if(data && data.length > 0) {
        return data.map(d => {
            let out = {};
            Object.keys(d).forEach(k => {
                if (!k.startsWith("Cogen")) {
                    out[k] = d[k];
                }
            });
            return out;
        });
    }
    return [];
}

function buildSeries(data, series, renewables) {
    renewables.forEach((f, idx)=>{
        const sortedData = [...data].map(d=>{
            return {
                [f]: d[f]
            }
        }).sort(function(a, b){
            return b[f] - a[f]
        }).map((d, idx)=> {
            return {...d, index: idx}
        });
        series.push({
            name: f,
            color: findColorByKey(f, idx),
            data: sortedData
        });
    });
}

function buildDataAggregated(series, aggData, renewables) {
    if(series.length > 0) {
        series.filter(f => f.name === renewables[0])[0]["data"].forEach(
            (d, idx) => {
                aggData[idx] = {
                    "index": d["index"]
                };
                renewables.forEach(r => {
                    aggData[idx] = {
                        ...aggData[idx],
                        [r]: series.filter(s => s.name === r)[0]["data"][idx][r]
                    }
                })
            })
    }
}

function RenewablesExcessOutput({simulationId, range, compareWith,
    groupBy, strategy}) {


    const [prices, setPrices] = useState([]);
    const [compareSimulations, setCompareSimulations] = useState([]);
    const [tabMode, setTabMode] = useState("charts");
    const [tabSelected, setTabSelected] = useState(1);

    useEffect(()=>{

    }, [tabMode, tabSelected]);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationId + '/results/excess?' + buildRangeParameters(range)+ '&aggregation='+ groupBy))
            .then(res => res.json()).then(data => {
            setPrices(data["data"]);
        });
    }, [simulationId, range, groupBy]);

    useEffect(() => {
        if(compareWith.length > 0) {
            if(groupBy === 'All') {
                compareWith.forEach(c => {
                    if (compareSimulations.filter(
                        cs => cs["simId"] === c).length === 0) {
                        trackPromise(
                            fetch('/m40alasocho/simulation/' + c + '/results/excess?'
                                + buildRangeParameters(range) + '&aggregation='
                                + groupBy))
                        .then(res => res.json()).then(data => {
                            setCompareSimulations(comp => [...comp, {
                                "data": [...data["data"]],
                                "simId": c
                            }
                            ]);
                        });
                    }
                });
                setCompareSimulations(
                    cs => cs.filter(c => compareWith.includes(c["simId"])));
            }
            else{
                sendNotification("This view can only be compared under Simulation Period", "info");
                setCompareSimulations([]);
            }
        }else{
            setCompareSimulations([]);
        }
        // eslint-disable-next-line
    }, [ range, compareWith, groupBy]);


    let byStrategy = prices.filter(g=>g["strategy"]).map(g=> {
        if(groupBy !== 'All' && "data" in g) {
            return {
                time: g["entryDate"],
                ...JSON.parse(g["data"])
            }
        }else{
            let out = {...g}
            delete out.strategy;
            return out;
        }
        });
    let byCompetitive = prices.filter(g=>g["strategy"] === false).map(g=> {
        if(groupBy !== 'All' && "data" in g) {
            return {
                time: g["entryDate"],
                ...JSON.parse(g["data"])
            }
        }else{
            let out = {...g}
            delete out.strategy;
            return out;
        }
    });
    byCompetitive = byCompetitive.map(d=>{
        if("Cogen" in d){
            let out = {...d}
            delete out["Cogen"];
            return out;
        }
        return d;
    });
    byStrategy = byStrategy.map(d=>{
        if("Cogen" in d){
            let out = {...d}
            delete out["Cogen"];
            return out;
        }
        return d;
    })

    let renewables = [];
    let visibilityMap = {};
    let strategySeries = [], competitiveSeries = [];
    let strategyAggregatedData = [], competitiveAggregatedData = [];

    let chartDataStrategic = [], chartDataCompetitive = [];
    if(byStrategy.length > 0) {
        renewables = Object.keys(byStrategy[0]).filter(k => k !== 'time').map(k => k);
        renewables.forEach(f=>{
            visibilityMap = {...visibilityMap, [f]:true}
        });
        chartDataStrategic = compareSimulations.length > 0 ? clearNotRequiredKeys(buildComparativeData(filterByStrategy(compareSimulations, true), byStrategy)) : byStrategy;
        buildSeries(chartDataStrategic, strategySeries, renewables);
        buildDataAggregated(strategySeries, strategyAggregatedData, renewables);
    }

    if(byCompetitive.length > 0) {
        chartDataCompetitive = compareSimulations.length > 0 ? clearNotRequiredKeys(buildComparativeData(filterByStrategy(compareSimulations, false), byCompetitive)) : byCompetitive;
        buildSeries(chartDataCompetitive, competitiveSeries, renewables);
        buildDataAggregated(competitiveSeries, competitiveAggregatedData, renewables);
    }

    const tableUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/excess?' + buildRangeParameters(range)+ '&aggregation='+ groupBy + '&mode=competitive';
    const dataTableComp =[...chartDataCompetitive].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))}));
    const competitiveTable = groupBy === 'Hour' && chartDataCompetitive && chartDataCompetitive.length > 0 ? (
        <TablePagination title={"Renewable Excess (Competitive)"} columns={buildColumnsFromData(chartDataCompetitive)} url={tableUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) :
        <BasicTable title={"Renewable Excess (Competitive)"} data={addTotals(dataTableComp)} columns={buildColumnsFromData(addTotals(dataTableComp))} options={{
            fixedHeaderScrollHeight: "350px"
        }}/>;

    const tableStrategicUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/excess?' + buildRangeParameters(range)+ '&aggregation='+ groupBy + '&mode=strategic';
    const dataTableStrat =[...chartDataStrategic].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))}));
    const strategicTable = groupBy === 'Hour' && chartDataStrategic && chartDataStrategic.length > 0 ? (
        <TablePagination title={"Generation Excess (Strategic)"} columns={buildColumnsFromData(chartDataStrategic)} url={tableStrategicUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) :
        <BasicTable title={"Generation Excess (Strategic)"} data={addTotals(dataTableStrat)} columns={buildColumnsFromData(addTotals(dataTableStrat))} options={{
            fixedHeaderScrollHeight: "350px"
        }}/>;

    let tabStrategicVsCompetitive = null;
    if(['Year', 'All'].includes(groupBy) && strategy && chartDataStrategic.length > 0){
        let aggregatedObject = {}
        Object.keys(chartDataStrategic[0]).forEach(k => {
            if(k !== "time") {
                aggregatedObject[k + '[S]'] = chartDataStrategic[0][k];
            }
        });
        Object.keys(chartDataCompetitive[0]).forEach(k => {
            if(k !== "time") {
                aggregatedObject[k + '[C]'] = chartDataCompetitive[0][k];
            }
        });

        tabStrategicVsCompetitive = <Tab eventKey={5} title="Competitive vs Strategic">
            <LineChartWrapper data={[aggregatedObject]}
                              aggregation={groupBy}
                              title={'Strategic vs Competitive'}
                              brushKey="time"
                              xAxisKey="index"
                              noDataMessage={"This chart is not available for the hourly aggregation"}
                              yAxisUnit="€/MWh" />
        </Tab>
    }

    const tabsByMode = tabMode === "charts" ? (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive) ">
                <AreaChartWrapper data={chartDataCompetitive}
                                  aggregation={groupBy}
                                  title="Excess Renewables (Competitive)" brushKey="time" xAxisKey="time" yAxisUnit="MWh" />
            </Tab>
            <Tab eventKey={2} title="Duration (Competitive) ">
                <AreaChartWrapper data={chartDataCompetitive}
                                  aggregation={groupBy}
                                  title="Excess Renewables (Competitive)" xAxisKey="index" yAxisUnit="MWh" />
            </Tab>
            {strategy &&
                <Tab eventKey={3} title="Chronological (Strategic) ">
                    <AreaChartWrapper data={chartDataStrategic}
                                      aggregation={groupBy}
                                      title="Excess Renewables (Strategic)"
                                      xAxisKey="time" yAxisUnit="MWh"/>
                </Tab>
            }
            {strategy &&
                <Tab eventKey={4} title="Duration (Strategic) ">
                    <AreaChartWrapper data={chartDataStrategic}
                                      aggregation={groupBy}
                                      title="Excess Renewables (Strategic)"
                                      xAxisKey="index" yAxisUnit="MWh"/>
                </Tab>
            }
            {tabStrategicVsCompetitive}
        </Tabs>
    ) : (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive) ">
                {competitiveTable}
            </Tab>
            {strategy &&
                <Tab eventKey={2} title="Chronological (Strategic) ">
                    {strategicTable}
                </Tab>
            }
        </Tabs>
    );

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <div className="recharts-component">
                <ChartTableToggle mode={tabMode} updateSelectedTab={setTabSelected} updateTabMode={setTabMode}/>
                <Row>
                    <Col sm={12}>
                        {tabsByMode}
                    </Col>
                </Row>
            </div>
        </ErrorBoundary>
    );
}

export default RenewablesExcessOutput;
