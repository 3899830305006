import React from "react";
import BarChartComponent from "./BarChartComponent";
import ScatterChartComponent from "./ScatterChartComponent";
import {orderKeys} from "../commons";

function ScatterChartWrapper({title, data, brushKey, xAxisKey,
  yAxisUnit, yAxisRight, aggregation, yAxisName, groupBy, xAxisName}) {

  let chart = null;
  if (groupBy && ['Year', 'All'].includes(groupBy) && data && data.length > 0){
    let chartData = [];
    let chartDataSorted = [];
    let chartBars = {};
    let sortedChartBars = {};
    data.forEach(k=>{
      const year_stat = k[0]
      chartData.push({
        [year_stat["name"]]: year_stat["z"],
        "name": year_stat["name"]
      });
      chartBars[year_stat["name"]] = year_stat["name"];
    });
    const ordered = orderKeys(chartBars);
    Object.keys(ordered).forEach(k=> {
      sortedChartBars[k] = chartBars[k];
      const item = chartData.filter(cd => cd["name"] === k)[0];
      chartDataSorted.push({
        [k]: item[k],
        "name": k
      })
    });
    chart = <BarChartComponent title={title} data={chartDataSorted} bars={sortedChartBars} xAxisKey={"name"} yAxisUnit={"%"} />;
  } else {
    chart = <ScatterChartComponent title={title}
                                   data={data}
                                   brushKey={brushKey}
                                   xAxisKey={xAxisKey}
                                   yAxisUnit={yAxisUnit}
                                   aggregation={aggregation}
                                   groupBy={groupBy}
                                   yAxisName={yAxisName}
                                   xAxisName={xAxisName}
                                   yAxisRight={yAxisRight}/>
  }

    return (
            <div className="recharts-component">
              {chart}
            </div>
    )
}
export default ScatterChartWrapper;
