import {Col, Form, Row} from "react-bootstrap";
import {sendNotification} from "../commons";
import React from "react";

function ChartTableToggle({mode, updateTabMode, updateSelectedTab}) {

    return (
        <Row>
            <Col sm={12} style={{marginBottom: "10px"}}>
                <Form.Check
                    type="switch"
                    id='tabsMode'
                    key='pricesTabMode'
                    checked={mode === "charts"}
                    style={{marginLeft: "0px", float: "right", cursor: "pointer"}}
                    onChange={(e) => {
                        let tabMode = e.target.checked ? "charts" : "data"
                        updateTabMode(tabMode);
                        updateSelectedTab(1);
                        sendNotification("Switched to " + tabMode + " view", "success");
                    }}
                    label={mode === "charts" ? 'Charts Mode' : 'Data Tables Mode'}
                />
            </Col>
        </Row>
    )
}

export default ChartTableToggle;