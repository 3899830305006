import React, {useEffect, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {trackPromise} from "react-promise-tracker";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {
    buildColumnsFromData,
    buildRangeParameters,
    deleteInterConnections,
    sendNotification,
    sortTechnologies
} from "../commons";
import ChartTableToggle from "../common/ChartTableToggle";
import TablePagination from "../common/TablePagination";
import ScatterChartWrapper from "../common/ScatterChartWrapper";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

function buildDataArray(data, groupBy, compareSimulations) {
    let dataArray = [];
    let compareArray = [];
    if(data && data.length > 0) {
        Object.keys(data[0]).filter(f => f !== 'time' && f !== 'aggregation' && f !== 'entryDate' && f !== 'strategy').forEach(
            (k, idx) => {
                dataArray.push(data.map((d, idx2) => {
                    if(groupBy === 'Hour') {
                        return {
                            "x": idx2,
                            "y": idx,
                            "z": parseInt(d[k]) !== 0 ? 15 : 0,
                            "time": d["time"],
                            "name": k
                        }
                    }else{
                        return {
                            "x": idx2,
                            "y": idx,
                            "z": d[k]["percentage"],
                            "time": d["time"],
                            "entryDate": d["entryDate"],
                            "name": k
                        }
                    }
                }));
            });
    }
    if(compareSimulations && Object.keys(compareSimulations).length > 0) {
        Object.keys(compareSimulations).forEach(simId=> {
            compareSimulations[simId].forEach(d => {
                Object.keys(d).filter(k => k !== 'strategy' && k !== 'time' && k !== 'aggregation').forEach(k => {
                    compareArray.push([{
                        "name": k + simId,
                        "z": d[k]["percentage"]
                    }]);
                });
            });
        });
    }
    return dataArray.concat(compareArray);
}


function PriceSetterOutput({simulationId, aggregation, range, compareWith,
    groupBy, strategy}) {

    const [prices, setPrices] = useState([]);
    const [dataStrategy, setDataStrategy] = useState([]);
    const [dataCompetitive, setDataCompetitive] = useState([]);
    const [compareSimulations, setCompareSimulations] = useState({});
    const [tabMode, setTabMode] = useState("charts");
    const [tabSelected, setTabSelected] = useState(1);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationId + '/results/priceSetter' + aggregation +'?' + buildRangeParameters(range)+ '&aggregation='+ groupBy))
            .then(res => res.json()).then(data => {
            setPrices(data["data"]);
        });
    }, [simulationId, aggregation, range, groupBy]);

    useEffect(() => {
        if(compareWith.length > 0) {
            if(groupBy === 'All') {
                compareWith.forEach(c => {
                    trackPromise(
                        fetch('/m40alasocho/simulation/' + c + '/results/priceSetter' + aggregation + '?' + buildRangeParameters(range) + '&aggregation=' + groupBy))
                        .then(res => res.json()).then(data => {
                            setCompareSimulations(cs=> {
                                return {...cs, [c]: [...data["data"]]}
                            });
                        });
                });
            }else{
                sendNotification("This view can only be compared under Simulation Period", "info");
                setCompareSimulations({});
            }
            Object.keys(compareSimulations).forEach(key =>{
               if(!compareWith.includes(key)){
                   setCompareSimulations(cs=> {
                       return delete cs[key];
                   });
               }
            });
        }else{
            setCompareSimulations({});
        }
        // eslint-disable-next-line
    }, [aggregation, range, compareWith, groupBy]);


    useEffect(() => {
        setDataStrategy(prices && prices.filter(f => f["strategy"]).map(
            p => {
                if (groupBy === 'Hour' && "data" in p) {
                    const data = sortTechnologies(JSON.parse(p["data"]), aggregation);
                    let out = {
                        "time": p.entryDate,
                        ...data
                    }
                    deleteInterConnections(out, ["MAR", "POR", "FRA", "Exp", "Imp", "Exp/Imp"]);
                    return out;
                } else {
                    const data = sortTechnologies(p, aggregation);
                    let out = {
                        "time": p.entryDate,
                        ...data
                    }
                    deleteInterConnections(out, ["MAR", "POR", "FRA", "Exp", "Imp", "Exp/Imp"]);
                    return out;
                }
            }));
        setDataCompetitive(prices && prices.filter(
            f => !f["strategy"]).map(p => {
            if (groupBy === 'Hour' && "data" in p) {
                const data = sortTechnologies(JSON.parse(p["data"]), aggregation);
                let out = {
                    "time": p.entryDate,
                    ...data
                }
                deleteInterConnections(out, ["MAR", "POR", "FRA", "Exp", "Imp", "Exp/Imp"]);
                return out;
            } else {
                const someDate = new Date(p.entryDate + " 00:00:00");
                const data = sortTechnologies(p, aggregation);
                let out = {
                    "time": someDate.getTime(),
                    ...data
                }
                deleteInterConnections(out, ["MAR", "POR", "FRA", "Exp", "Imp", "Exp/Imp"]);
                return out;
            }
        }));
    }, [prices, groupBy, aggregation]);


    const tableUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/priceSetter' + aggregation + '?' + buildRangeParameters(range) + '&aggregation='+ aggregation + '&mode=strategic';
    const tableStrategic = dataStrategy && dataStrategy.length > 0 ? (
        <TablePagination title="Chronological (Strategic)" columns={buildColumnsFromData(dataStrategy)} url={tableUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) : null;

    const tableCompetitiveUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/priceSetter' + aggregation + '?' + buildRangeParameters(range) + '&aggregation='+ aggregation + '&mode=competitive';
    const tableCompetitive = dataCompetitive && dataCompetitive.length > 0 ? (
        <TablePagination title="Chronological (Competitive)" columns={buildColumnsFromData(dataCompetitive)} url={tableCompetitiveUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) : null;

    let dataArray = [];
    let dataArrayStrategic = [];

    dataArray = buildDataArray(dataCompetitive, groupBy, compareSimulations);
    dataArrayStrategic = buildDataArray(dataStrategy, groupBy, compareSimulations);

    const tabsByMode = tabMode === "charts" ? (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive) ">
                <ScatterChartWrapper data={dataArray}
                                     aggregation={aggregation}
                                     xAxisName={"Time"}
                                     yAxisName={aggregation}
                                     groupBy={groupBy}
                                     title={"Price Setters by " + aggregation + " (Competitive)"} xAxisKey="index" brushKey="index" yAxisUnit="" />
            </Tab>
            {strategy &&
                <Tab eventKey={2} title="Chronological (Strategic) ">
                    <ScatterChartWrapper
                            data={dataArrayStrategic}
                            aggregation={aggregation}
                            xAxisName={"Time"}
                            yAxisName={aggregation}
                            groupBy={groupBy}
                            title={"Price Setters by " + aggregation
                                + " (Strategic)"}
                            xAxisKey="index" brushKey="index" yAxisUnit=""/>
                </Tab>
            }
        </Tabs>
    ) : (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive) ">
                {tableCompetitive}
            </Tab>
            {strategy &&
                <Tab eventKey={2} title="Chronological (Strategic) ">
                    {tableStrategic}
                </Tab>
            }
        </Tabs>
    );

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <div className="recharts-component">
                <ChartTableToggle mode={tabMode} updateSelectedTab={setTabSelected} updateTabMode={setTabMode}/>
                <Row>
                    <Col sm={12}>
                        {tabsByMode}
                    </Col>
                </Row>
            </div>
        </ErrorBoundary>
    );
}

export default PriceSetterOutput;
