import {Button, Col, Row} from "react-bootstrap";
import InvestmentPlantConfiguration from "./InvestmentPlantConfiguration";


const InvestmentPlants = ({ plants, projectType, addPlant, updatePlant, removePlant }) => {

    return <div>
            <Button variant="secondary" onClick={addPlant} hidden={plants.length === 4}>
                Add Plant
            </Button>
            <Row style={{marginTop: "20px"}}>
                {
                    plants.map((k, idx) => {
                        return <Col sm={6} key={idx}>
                            <InvestmentPlantConfiguration plant={k}
                                                          projectType={projectType}
                                                          index={idx}
                                                          updatePlant={updatePlant}
                                                          removePlant={removePlant}
                            />
                        </Col>
                    })
                }
            </Row>
        </div>

}

export default InvestmentPlants