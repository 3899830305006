import React from "react";
import LineChartComponent from "./LineChartComponent";
import BarChartComponent from "./BarChartComponent";
import {orderKeys} from "../commons";


function LineChartWrapper({title, data, brushKey, xAxisKey,
                            yAxisUnit, yAxisRight, yAxisType, yAxisLabel, isDomainPercentage,
                            aggregation, decimals, noDataMessage}) {


  let chart = null;
  if (aggregation && ['Year', 'All'].includes(aggregation)
      && data && data.length === 1){
    let chartData = [];
    let chartBars = {}
    const ordered = orderKeys(data[0]);

    Object.keys(ordered).forEach(k=>{
      if(k !== 'time'){
        chartData.push({
          [k]: data[0][k],
          "name": k
        });
        chartBars[k] = k;
      }
    });
    chart = <BarChartComponent title={title} data={chartData} bars={chartBars} xAxisKey={"name"} yAxisUnit={yAxisUnit} yAxisRight={yAxisRight}/>;
  }else{
    chart = <LineChartComponent data={data}
                                title={title}
                                brushKey={brushKey}
                                xAxisKey={xAxisKey}
                                yAxisUnit={yAxisUnit}
                                yAxisRight={yAxisRight}
                                yAxisType={yAxisType}
                                yAxisLabel={yAxisLabel}
                                aggregation={aggregation}
                                isDomainPercentage={isDomainPercentage}
                                decimals={decimals}
                                noDataMessage={noDataMessage}
    />
  }

    return (
            <div className="recharts-component">
              {chart}
            </div>
    )
}
export default LineChartWrapper;
