import React, {useEffect, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {trackPromise} from "react-promise-tracker";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import LineChartWrapper from "../common/LineChartWrapper";
import {
    addTotals,
    buildColumnsFromData,
    buildComparativeData,
    buildRangeParameters,
    convertToMomentTz,
    formatNumbersDecimals,
    getDateFormatByAggregation
} from "../commons";
import ChartTableToggle from "../common/ChartTableToggle";
import TablePagination from "../common/TablePagination";
import BasicTable from "../common/BasicTable";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}


function parseData(data, aggregation) {
    return data["data"].map(d => {
        let out = {};
        out["time"] = d["entryDate"];
        const o = aggregation !== 'All' ? JSON.parse(d["data"]) : {...d};
        Object.keys(o).forEach(k => {
            if(k !== 'entryDate') {
                out[k] = o[k];
            }
        })
        return out;
    });
}

function PriceOutput({simulationId, range, compareWith, aggregation, simulation, compareWithSeries}) {

    const [prices, setPrices] = useState([]);
    const [tabMode, setTabMode] = useState("charts");
    const [tabSelected, setTabSelected] = useState(1);
    const [compareSimulations, setCompareSimulations] = useState([]);
    const [compareSimulationsBySeries, setCompareSimulationsBySeries] = useState([]);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationId + '/results/marketPrice?'
                + buildRangeParameters(range) + '&aggregation='+ aggregation
                + '&allData=' + ((compareWith.length > 0 || compareWithSeries.length > 0) ? '1' : '0')))
            .then(res => res.json()).then(data => {
                setPrices(
                    data["data"].map(d=>{
                        if(aggregation !== 'All') {
                            return {
                                'entryDate': d['entryDate'],
                                ...JSON.parse(d["data"])
                            }
                        }else{
                            return d;
                        }
                    })
                );
        });

    }, [simulationId, range, aggregation, compareWith, compareWithSeries]);

    useEffect(()=>{
        if(compareWith.length > 0) {
            compareWith.forEach(c=>{
                trackPromise(
                    fetch('/m40alasocho/simulation/' + c + '/results/marketPrice?'
                        + buildRangeParameters(range) + '&aggregation='
                        + aggregation + '&allData='
                        + ((compareWith.length > 0) ? '1' : '0')))
                    .then(res => res.json()).then(data => {
                        if(compareSimulations.filter(cs=>cs["simId"]===c).length === 0) {
                            setCompareSimulations(comp => [...comp, {
                                "data": [...parseData(data, aggregation)],
                                "simId": c
                            }]);
                        }else{
                            setCompareSimulations(comp => comp.map(cs=>{
                                if(cs["simId"] !== c){
                                    return cs;
                                }else{
                                    return {
                                        ...cs,
                                        "data": [...parseData(data, aggregation)]
                                    }
                                }
                            }));
                        }
                });
            });
            setCompareSimulations(cs => cs.filter(c=>compareWith.includes(c["simId"])));
        }
        else{
            setCompareSimulations([]);
        }
        // eslint-disable-next-line
    }, [compareWith, range, aggregation]);

    useEffect(()=>{
        if(compareWithSeries.length > 0) {
            compareWithSeries.forEach(c=>{
                trackPromise(
                    fetch('/m40alasocho/simulation/' + simulationId + '/data/' + c + '/price/compare?'
                        + buildRangeParameters(range) + '&aggregation=' + aggregation + '&allData=1'))
                    .then(res => res.json()).then(data => {
                    if(compareSimulationsBySeries.filter(cs=>cs["ds"]===c).length === 0) {
                        setCompareSimulationsBySeries(comp => [...comp, {
                            "data": [...data["data"].map(d=>{
                                return {
                                    "time": d.time,
                                    "DS-price-": d.maxPrice
                                }
                            })],
                            "ds": c
                        }]);
                    }else{
                        setCompareSimulationsBySeries(comp => comp.map(cs=>{
                            if(cs["ds"] !== c){
                                return cs;
                            }else{
                                return {
                                    ...cs,
                                    "data": [...data["data"].map(d=>{
                                        return {
                                            "time": d.time,
                                            "DS-price-": d.maxPrice
                                        }
                                    })],
                                }
                            }
                        }));
                    }
                });
            });
        }else{
            setCompareSimulationsBySeries([]);
        }
        // eslint-disable-next-line
    }, [range, aggregation, compareWithSeries]);

    useEffect(()=>{

    }, [tabMode, tabSelected, simulationId]);


    const data = prices && prices.map(p=>{
        let out = {
            ...p,
            "time": p.entryDate
        }
        delete out.entryDate;
        return out;
    });


    const tableData = formatNumbersDecimals([...data].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(aggregation))})));

    const tableUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/marketPrice?' + buildRangeParameters(range) + '&aggregation='+ aggregation;
    const pricesTable = aggregation === 'Hour' && prices && prices.length > 0 ? (
        <TablePagination title="Market Prices (Cronological)" columns={buildColumnsFromData(tableData)} url={tableUrl}/>
    ) :
        <BasicTable title={"Market Prices (Cronological)"} data={addTotals(tableData)} columns={buildColumnsFromData(tableData)} options={{
            fixedHeaderScrollHeight: "350px"
        }}/>;

    const chartData = compareSimulations.length > 0 || compareSimulationsBySeries.length > 0 ? buildComparativeData(compareSimulations.concat(compareSimulationsBySeries), data) : data;

    const tabsByMode = tabMode === "charts" ? (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological ">
                <LineChartWrapper data={chartData}
                                  aggregation={aggregation}
                                  title="Market Prices " brushKey="time" xAxisKey="time" yAxisUnit="€/MWh"/>
            </Tab>
            <Tab eventKey={2} title="Duration ">
                <LineChartWrapper data={chartData}
                                  aggregation={aggregation}
                                  title="Market Prices " xAxisKey="index" yAxisUnit="€/MWh" />
            </Tab>
        </Tabs>
    ) : (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological ">
                <div style={{marginTop: "15px"}}>
                    {pricesTable}
                </div>
            </Tab>
        </Tabs>
    );

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
                <div className="recharts-component">
                    <ChartTableToggle mode={tabMode} updateSelectedTab={setTabSelected} updateTabMode={setTabMode}/>
                    <Row>
                        <Col sm={12}>
                            {tabsByMode}
                        </Col>
                    </Row>
                </div>
        </ErrorBoundary>
    );
}

export default PriceOutput;
