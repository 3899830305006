import React from "react";
import DataTable from 'react-data-table-component';
import CsvDownload from 'react-json-to-csv'
import {GrDocumentCsv} from "react-icons/gr";
import {formatNumbersDecimals} from "../commons";


const BasicTable = ({ title, data, columns, options }) => {

    const defaultOpts = {
        fixedHeaderScrollHeight: "250px",
    };

    const props = options ? options : defaultOpts;

    const iconsStyle = {
        marginLeft:"15px",
        cursor: "pointer"
    };

    return (
        <div style={{width: "100%"}}>
            <h6>
                {title}
                <CsvDownload
                    data={data}
                    filename={title + ".csv"}
                    className="btn btn-link btn-sm"
                    style={{color: "black"}}
                >
                    <GrDocumentCsv style={iconsStyle} title="Download CSV"/>
                </CsvDownload>
            </h6>
            <div id={"idDatatableContainer"} style={{overflowX: "scroll", height:"450px",
                        width:"100%",
                        overflow:"scroll",
                        whiteSpace:"nowrap"}}>
                <DataTable
                    fixedHeader={true}
                    highlightOnHover={true}
                    responsive={true}
                    striped={true}
                    dense={true}
                    columns={columns}
                    data={formatNumbersDecimals(data)}
                    {...props}
                    pagination
                />
            </div>
        </div>
  );
};

export default BasicTable;
