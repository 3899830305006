import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import {Button, Container, Nav, Navbar} from "react-bootstrap";
import MarketStructure from "./components/market/MarketStructure";
import Simulations from "./components/simulations/Simulations";
import DataSeries from "./components/data/DataSeries";
import React from "react";
import Login from "./components/login/Login";
import useToken from "./components/login/useToken";
import Users from "./components/users/Users";

import white_logo_small from "./img/Logo ENERGEIA small white.png"
import Investments from "./components/investments/Investments";

function App() {

  const { token, setToken } = useToken();

  if (!token || !token.key) {
    return <Login setToken={setToken} />
  }

  const logout = () => {
    setToken({});
    window.location.href = "/";
  };

  const username = token.user ? (token.user.name + ' ' + token.user.surname) : 'Log in';

  document.body.style.backgroundImage = null;

  const usersAdmin = token.user.role === "ADMIN" ? <Nav.Link href="/users">USERS</Nav.Link> : null;
  const investmentsTab = token.user.investments ? <Nav.Link href="/investments">INVESTMENTS</Nav.Link> : null;

  return (
    <div className="App">
      <Router>
        <div>
          <Navbar expand="lg">
            <Container>
              <Navbar.Brand href="/"><img className='logo-small' src={white_logo_small} alt="EnergeIA"></img></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                  <Nav.Link href="/market/structure">MARKET STRUCTURE</Nav.Link>
                  <Nav.Link href="/data/series">DATA SERIES</Nav.Link>
                  <Nav.Link href="/simulations">SIMULATIONS</Nav.Link>
                  {investmentsTab}
                  {usersAdmin}
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse className="justify-content-end">
                <Nav.Link href="/about"> About</Nav.Link>
                <Nav.Link href="/help"> Help</Nav.Link>
                <Navbar.Text className='signed-as'>
                  Signed in as: <span style={{ color: "white" }}>{username}</span>
                </Navbar.Text>
              </Navbar.Collapse>
              <Nav>
                <Button variant="secondary" className="logout-button" size="sm" style={{ marginLeft: "10px" }} onClick={logout}>LOGOUT</Button>
              </Nav>
            </Container>
          </Navbar>
          <Routes>
            <Route path="/market/structure" element={<MarketStructure user={token.user} username={token.user.username} />}/>
            <Route path="/data/series" element={<DataSeries user={token.user} username={token.user.username} />} />
            <Route path="/simulations/:simId" element={<Simulations userId={token.user.id} username={token.user.username} />} />
            <Route path="/simulations" element={<Simulations userId={token.user.id} username={token.user.username} />} />
            <Route path="/investments/:simId" element={<Investments userId={token.user.id} username={token.user.username} />} />
            <Route path="/investments" element={<Investments userId={token.user.id} username={token.user.username} />} />
            <Route path="/users" element={<Users />} />
            <Route path="/" element={<Home user={token.user}/>} />
          </Routes>
          <Container fluid>
            <div className='footer'>
              <p>©Copyright. All rights reserved</p>
            </div>
          </Container>
        </div>
      </Router>
    </div>
  );
}

export default App;
