import {Button, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {sendNotification} from "../commons";
import {trackPromise} from "react-promise-tracker";
import CreatableSelect from "react-select/creatable";

function RenameSimulation({
                              show,
                              simulation,
                              userId,
                              updateSimulationList,
                              updateSimulation,
                              type
                          }){

    const [showRename, setShowRename] = useState(show);
    const [newSimulationName, setNewSimulationName] = useState(simulation.name);
    const [selectedTag, setSelectedTag] = useState(simulation.tag);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/tags'))
            .then(res => res.json()).then(data => {
                let tagsArray = [];
                data.list.forEach(t=>{
                    if(t.tag !== null) {
                        tagsArray.push({
                            label: t.tag,
                            value: t.tag
                        });
                    }
                });
                setTags(tagsArray);
        });
    }, []);

    const renameSimulation = () => {
        if(newSimulationName.trim() === ''){
            sendNotification('The new simulation name is empty', "warning");
            return false;
        }

        trackPromise(
            fetch('/m40alasocho/simulation/' + simulation.id + '/rename/' + userId, {
                method: 'POST' ,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: newSimulationName,
                    tag: selectedTag,
                    type: type
                })
            }))
            .then(res => res.json()).then(data => {
            if("error" in data){
                sendNotification(data.error, "danger");
            }else {
                setShowRename(!showRename);
                updateSimulationList(data.list);
                updateSimulation(data.simulation);
                sendNotification('Simulation renamed', "success");
            }
        });
    }

    let selectOpts = {};
    if(selectedTag){
        selectOpts = {
            value: {
                value: selectedTag, label: selectedTag
            }
        }
    }

    return (
        <Row>
            <Modal show={showRename} onHide={()=>{setShowRename(!showRename)}} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Rename Simulation {simulation.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <fieldset>
                        <Row>
                            <Form.Label>Name
                                <Form.Control type="text" placeholder="Simulation Name"
                                              value={newSimulationName}
                                              size="sm" onChange={event=>{
                                    setNewSimulationName(event.target.value);
                                }}/>
                            </Form.Label>
                        </Row>
                        <Row>
                            <Form.Label>Tag
                                <CreatableSelect isClearable
                                                 name={"tag"}
                                                 {...selectOpts}
                                                 options={tags}
                                                 placeholder={"Select" +
                                    " or create a Tag for this simulation"}
                                                 onChange={newValue => setSelectedTag(newValue.value)}
                                />
                            </Form.Label>
                        </Row>
                    </fieldset>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRename(!showRename)}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={renameSimulation}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Row>
        )

}

export default RenameSimulation;