import React, {useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import AreaChartWrapper from "../common/AreaChartWrapper";
import BasicTable from "../common/BasicTable";
import {buildColumnsFromData, convertToMomentTz, getDateFormatByAggregation} from "../commons";
import ChartTableToggle from "../common/ChartTableToggle";
import {Col, Row} from "react-bootstrap";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

function HydroDataChart({data, all}) {

    const [tabMode, setTabMode] = useState("charts");

    const tableData = [...all].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation("Hour"))}));

    const dataByMode = tabMode === "charts" ? (
        <AreaChartWrapper data={data.map(d => ({...d , "dispatch" : +d.dispatch, "river" : +d.river}))}
                          title="Hydro Production" brushKey="time" xAxisKey="time" yAxisUnit="MWh" />
    ) : (
        <div style={{marginTop: "15px"}}>
            <BasicTable title={"Hydro Production"} data={tableData} columns={buildColumnsFromData(tableData)} options={{
                fixedHeaderScrollHeight: "350px"
            }}/>;
        </div>
    );

    return data && data.length > 0 ? (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <div className="recharts-component">
                <ChartTableToggle mode={tabMode} updateTabMode={setTabMode} updateSelectedTab={()=>{}}/>
                <Row>
                    <Col sm={12}>
                        {dataByMode}
                    </Col>
                </Row>
            </div>
        </ErrorBoundary>
    ) : <h1>No data found</h1>;
}

export default HydroDataChart;
