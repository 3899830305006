import React, {useEffect, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {trackPromise} from "react-promise-tracker";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {
    addTotals,
    buildColumnsFromData,
    buildComparativeData,
    buildRangeParameters,
    convertToMomentTz,
    deleteInterConnections,
    divideUnitsBy,
    filterByStrategy,
    getDateFormatByAggregation,
    sendNotification,
    sortTechnologies
} from "../commons";
import AreaChartWrapper from "../common/AreaChartWrapper";
import ChartTableToggle from "../common/ChartTableToggle";
import LineChartWrapper from "../common/LineChartWrapper";
import TablePagination from "../common/TablePagination";
import BasicTable from "../common/BasicTable";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

function GenerationOutput({simulationId, aggregation, range,
    strategy, groupBy, compareWith, compareWithSeries}) {

    const [prices, setPrices] = useState([]);
    const [compareSimulations, setCompareSimulations] = useState([]);
    const [tabMode, setTabMode] = useState("charts");
    const [tabSelected, setTabSelected] = useState(1);
    const [compareSimulationsBySeries, setCompareSimulationsBySeries] = useState([]);

    useEffect(()=>{

    }, [tabMode, tabSelected]);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationId + '/results/generation'
                + aggregation + '?' + buildRangeParameters(range)
                + '&aggregation='+ groupBy
                + '&allData='
                + ((compareWith.length > 0) ? '1' : '0')))
            .then(res => res.json()).then(data => {
            setPrices(data["data"]);
        });
    }, [simulationId, aggregation, range, groupBy, compareWith]);

    useEffect(() => {
        if(compareWith.length > 0) {
            if(groupBy === 'All') {
                compareWith.forEach(c => {
                    trackPromise(
                        fetch('/m40alasocho/simulation/' + c + '/results/generation'
                            + aggregation + '?' + buildRangeParameters(range)
                            + '&aggregation=' + groupBy
                            + '&allData=' + ((compareWith.length > 0) ? '1'
                                : '0')))
                    .then(res => res.json()).then(data => {
                        if (compareSimulations.filter(
                            cs => cs["simId"] === c).length === 0) {
                            setCompareSimulations(comp => [...comp, {
                                "data": [...data["data"]],
                                "simId": c
                            }]);
                        } else {
                            setCompareSimulations(comp => comp.map(cs => {
                                if (cs["simId"] !== c) {
                                    return cs;
                                } else {
                                    return {
                                        ...cs,
                                        "data": [...data["data"]]
                                    }
                                }
                            }));
                        }
                    });
                });
            }else{
                sendNotification("This view can only be compared under Simulation Period", "info");
                setCompareSimulations([]);
            }
            setCompareSimulations(cs => cs.filter(c=>compareWith.includes(c["simId"])));
        }else{
            setCompareSimulations([]);
        }
        // eslint-disable-next-line
    }, [range, compareWith, aggregation, groupBy]);

    useEffect(()=>{
        if((groupBy === 'Year' || groupBy ==='All') && aggregation === "Technology" && compareWithSeries.length > 0) {
            compareWithSeries.forEach(c=>{
                trackPromise(
                    fetch('/m40alasocho/simulation/' + simulationId + '/data/' + c + '/technology/compare?'
                        + buildRangeParameters(range) + '&aggregation=' + groupBy))
                    .then(res => res.json()).then(data => {
                    if(compareSimulationsBySeries.filter(cs=>cs["ds"]===c).length === 0) {
                        setCompareSimulationsBySeries(comp => [...comp, {
                            "data": [...data["data"].map(d=>{
                                if(groupBy === 'All'){
                                    return d;
                                }else {
                                    let out = {
                                        "time": d.time,
                                    }
                                    Object.keys(d["data"]).forEach(k => {
                                        out[k] = d["data"][k]
                                    });
                                    return out;
                                }
                            })],
                            "ds": c
                        }]);
                    }else{
                        setCompareSimulationsBySeries(comp => comp.map(cs=>{
                            if(cs["ds"] !== c){
                                return cs;
                            }else{
                                return {
                                    ...cs,
                                    "data": [...data["data"].map(d=>{
                                        if(groupBy === 'All'){
                                            return d
                                        }else {
                                            let out = {
                                                "time": d.time,
                                            }
                                            Object.keys(d["data"]).forEach(k => {
                                                out[k] = d["data"][k]
                                            });
                                            return out;
                                        }
                                    })],
                                }
                            }
                        }));
                    }
                });
            });
        }else{
            if(aggregation === 'Technology' && compareWithSeries.length > 0 && ["Hour", "Day", "Month"].includes(groupBy)){
                sendNotification("The comparison with real data is not possible for this level of aggregation ", "warning");
            }
            setCompareSimulationsBySeries([]);
        }
        // eslint-disable-next-line
    }, [range, aggregation, compareWithSeries, groupBy]);

    let byStrategy = prices.filter(g=>g["strategy"]).map(g=> {
        if(groupBy !== 'All' && "data" in g) {
            const technologies = sortTechnologies(JSON.parse(g["data"]), aggregation);
            let out = {
                time: g["entryDate"],
                ...technologies
            }
            deleteInterConnections(out, ["MAR", "POR", "FRA"]);
            return out;
        }else{
            let out = {
                ...g
            }
            delete out.strategy;
            deleteInterConnections(out, ["MAR", "POR", "FRA"]);
            return out;
        }
        });
    let byCompetitive = prices.filter(g=>g["strategy"] === false).map(g=> {
        if(groupBy !== 'All' && "data" in g) {
            const technologies = sortTechnologies(JSON.parse(g["data"]), aggregation)
            let out = {
                time: g["entryDate"],
                ...technologies
            }
            deleteInterConnections(out, ["MAR", "POR", "FRA"]);
            return out;
        }else{
            let out = {
                ...g
            }
            delete out.strategy;
            deleteInterConnections(out, ["MAR", "POR", "FRA"]);
            return out;
        }
    });

    const chartDataStrategic = compareSimulations.length > 0 || compareSimulationsBySeries.length > 0 ? buildComparativeData(filterByStrategy(compareSimulations, true).concat(compareSimulationsBySeries), byStrategy) : byStrategy;
    const chartDataCompetitive = compareSimulations.length > 0 || compareSimulationsBySeries.length > 0 ? buildComparativeData(filterByStrategy(compareSimulations, false).concat(compareSimulationsBySeries), byCompetitive) : byCompetitive;

    const tableUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/generation' + aggregation + '?' + buildRangeParameters(range) + '&aggregation=' + groupBy + '&mode=competitive';
    const dataTableComp =[...chartDataCompetitive].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))}));

    const competitiveTable = groupBy === 'Hour' && chartDataCompetitive && chartDataCompetitive.length > 0 ? (
        <TablePagination title={"Generation By " + aggregation + " (Competitive)"} columns={buildColumnsFromData(chartDataCompetitive)} url={tableUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) :
        <BasicTable title={"Generation By " + aggregation + " (Competitive)"} data={addTotals(dataTableComp)} columns={buildColumnsFromData(addTotals(dataTableComp))} options={{
            fixedHeaderScrollHeight: "450px"
        }}/>
    ;

    const tableStrategicUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/generation' + aggregation + '?' + buildRangeParameters(range) + '&aggregation=' + groupBy + '&mode=strategic';
    const dataTableStrategic =[...chartDataStrategic].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))}));
    const strategicTable = groupBy === 'Hour' && chartDataStrategic && chartDataStrategic.length > 0 ? (
        <TablePagination title={"Generation By " + aggregation + " (Strategic)"} columns={buildColumnsFromData(chartDataStrategic)} url={tableStrategicUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) :
        <BasicTable title={"Generation By " + aggregation + " (Strategic)"} data={addTotals(dataTableStrategic)} columns={buildColumnsFromData(addTotals(dataTableStrategic))} options={{
            fixedHeaderScrollHeight: "350px"
        }}/>;

    const chartDataCompetitiveMap = divideUnitsBy(chartDataCompetitive, 1000);
    const chartDataStrategicMap = divideUnitsBy(chartDataStrategic, 1000);

    let tabStrategicVsCompetitive = null;
    if(['Year', 'All'].includes(groupBy) && strategy && chartDataStrategicMap.length > 0){
        let aggregatedObject = {}
        Object.keys(chartDataStrategicMap[0]).forEach(k => {
            if(k !== "time") {
                aggregatedObject[k + '[S]'] = chartDataStrategicMap[0][k];
            }
        });
        Object.keys(chartDataCompetitiveMap[0]).forEach(k => {
            if(k !== "time") {
                aggregatedObject[k + '[C]'] = chartDataCompetitiveMap[0][k];
            }
        });

        tabStrategicVsCompetitive = <Tab eventKey={5} title="Competitive vs Strategic">
            <LineChartWrapper data={[aggregatedObject]}
                              aggregation={groupBy}
                              title={'Strategic vs Competitive'}
                              brushKey="time"
                              xAxisKey="index"
                              noDataMessage={"This chart is not available for the hourly aggregation"}
                              yAxisUnit="€/MWh" />
        </Tab>
    }


    const tabsByMode = tabMode === "charts" ? (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive) ">
                <AreaChartWrapper data={chartDataCompetitiveMap}
                                  title={'Generation By ' + aggregation + ' (Competitive)'}
                                  brushKey="time"
                                  xAxisKey="time"
                                  yAxisUnit="GWh"
                                  aggregation={groupBy}/>
            </Tab>
            <Tab eventKey={2} title="Duration (Competitive)">
                <LineChartWrapper data={chartDataCompetitiveMap}
                                    aggregation={groupBy}
                                  title={'Generation By ' + aggregation + ' (Competitive)'} brushKey="time" xAxisKey="index" yAxisUnit="GWh" />
            </Tab>
            {strategy &&
                <Tab eventKey={3} title="Chronological (Strategic) ">
                    <AreaChartWrapper data={chartDataStrategicMap}
                                      aggregation={groupBy}
                                      title={'Generation By ' + aggregation
                                          + ' (Strategic)'}
                                      brushKey="time" xAxisKey="time"
                                      yAxisUnit="GWh"/>
                </Tab>
            }
            {strategy &&
                <Tab eventKey={4} title="Duration (Strategic)">
                    <LineChartWrapper data={chartDataStrategicMap}
                    aggregation={groupBy}
                    title={'Generation By ' + aggregation + ' (Strategic)'} brushKey="time" xAxisKey="index" yAxisUnit="GWh" />
                </Tab>
            }
            {tabStrategicVsCompetitive}
        </Tabs>
    ) : (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive) ">
                {competitiveTable}
            </Tab>
            {strategy &&
                <Tab eventKey={2} title="Chronological (Strategic) ">
                    {strategicTable}
                </Tab>
            }
        </Tabs>
    );

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
                <div className="recharts-component">
                    <ChartTableToggle mode={tabMode} updateSelectedTab={setTabSelected} updateTabMode={setTabMode}/>
                    <Row>
                        <Col sm={12}>
                            {tabsByMode}
                        </Col>
                    </Row>
                </div>
        </ErrorBoundary>
    );
}

export default GenerationOutput;
