import {Col, ListGroup, Row} from "react-bootstrap";
import {RiErrorWarningFill} from "react-icons/ri";


const SimulationWarnings = ({ messages }) => {

    const entriesKeys = ['Missing Demand entries', 'Missing Coal entries',
        'Missing Solar TH entries', 'Missing Wind entries',
        'Missing Solar PV entries', 'Missing Cogen entries',
        'Missing Others entries', 'Missing Hydro Flow entries',
        'Missing Hydro Dispatch entries', 'Missing Gas entries',
        'Missing Co2 entries', 'Missing Tax entries'];

    return <div>
            <ListGroup variant="flush">
                {
                    Object.keys(messages).map((k, idx) => {
                        let warningMessage = messages[k];
                        if(entriesKeys.includes(k)){
                            warningMessage = messages[k].map((m, i)=><div key={i} style={{width: "100%"}}>{m}</div>)
                        }
                        return <ListGroup.Item key={idx}>
                            <Row>
                                <Col xs={1}>
                                    <RiErrorWarningFill style={{color: "orange"}}/>
                                </Col>
                                <Col xs={8} className={"text-left"}>
                                    {warningMessage}
                                </Col>
                                <Col xs={3} className={"text-left"}>
                                    {k}
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    })
                }
            </ListGroup>
        </div>

}

export default SimulationWarnings