import React, {useEffect, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {trackPromise} from "react-promise-tracker";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {
    buildColumnsFromData,
    buildComparativeData,
    buildRangeParameters,
    convertToMomentTz,
    filterByStrategy,
    getDateFormatByAggregation,
    processItemToJson
} from "../commons";
import ChartTableToggle from "../common/ChartTableToggle";
import TablePagination from "../common/TablePagination";
import BasicTable from "../common/BasicTable";
import AreaChartWrapper from "../common/AreaChartWrapper";
import LineChartWrapper from "../common/LineChartWrapper";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

const renewablesGroup1 = ['CCGT (max)', 'CCGT (min)', 'Coal (min)', 'Coal (max)',
    'Imp/Exp (min)', 'Imp/Exp (max)', 'Nuclear (min)', 'Nuclear (max)'];
const renewablesGroup2 = ['Solar PV (max)', 'Solar PV (min)','Solar Thermal (max)',
    'Solar Thermal (min)', 'Wind (min)', 'Wind (max)','Other renewables (min)', 'Other renewables (max)'];

function buildDataInGroups(data, group1, group2) {
    data.forEach(c=>{
        let data1 = {};
        let data2 = {}
        Object.keys(c).forEach(k=>{
            if(k === 'time' || renewablesGroup1.includes(k)){
                data1[k] = c[k];
            }
            if(k === 'time' || renewablesGroup2.includes(k)){
                data2[k] = c[k];
            }
        });
        group1.push(data1);
        group2.push(data2);
    });
}

function buildSubGroupsData(subKeysGroup1, chartDataCompGroup1, subChartsGroup1) {
    subKeysGroup1.forEach(kg => {
        chartDataCompGroup1.forEach(c => {
            let data = {};
            Object.keys(c).forEach(k => {
                if (k === 'time' || k.startsWith(kg)) {
                    data[k] = c[k];
                }
            });
            subChartsGroup1[kg].push(data);
        });
    });
}

function MarginalCostsOutput({simulationId, range, compareWith, groupBy,
                            strategy}) {

    const [prices, setPrices] = useState([]);
    const [compareSimulations, setCompareSimulations] = useState([]);
    const [tabMode, setTabMode] = useState("charts");
    const [tabSelected, setTabSelected] = useState(1);

    useEffect(()=>{

    }, [tabMode, tabSelected]);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationId + '/results/marginalCosts?'
                + buildRangeParameters(range)+ '&aggregation='+ groupBy
                + '&allData=' + ((compareWith.length > 0) ? '1' : '0')))
            .then(res => res.json()).then(data => {
            setPrices(data["data"]);
        });
    }, [simulationId, range, groupBy, compareWith]);

    useEffect(() => {
        if(compareWith.length > 0) {
            compareWith.forEach(c=>{
                trackPromise(
                    fetch('/m40alasocho/simulation/' + c + '/results/marginalCosts?'
                        + buildRangeParameters(range)+ '&aggregation='+ groupBy
                        + '&allData=' + ((compareWith.length > 0) ? '1' : '0')))
                    .then(res => res.json()).then(data => {
                    if(compareSimulations.filter(cs=>cs["simId"]===c).length === 0) {
                        setCompareSimulations(comp => [...comp, {
                            "data": [...data["data"].map(d=>{
                                let out = processItemToJson(d);
                                out["strategy"] = d["strategy"];
                                return out;
                            })],
                            "simId": c
                        }]);
                    }else{
                        setCompareSimulations(comp => comp.map(cs=>{
                            if(cs["simId"] !== c){
                                return cs;
                            }else{
                                return {
                                    ...cs,
                                    "data": [...data["data"].map(d=>{
                                        let out = processItemToJson(d);
                                        out["strategy"] = d["strategy"];
                                        return out;
                                    })]
                                }
                            }
                        }));
                    }
                });
            });
            setCompareSimulations(cs => cs.filter(c=>compareWith.includes(c["simId"])));
        }else{
            setCompareSimulations([]);
        }
        // eslint-disable-next-line
    }, [range, compareWith, groupBy]);

    const costsByStrat = prices.filter(g=>g["strategy"]).map(g=> {
        return processItemToJson(g);
    });

    const costsByComp = prices.filter(g=>!g["strategy"]).map(g=> {
        return processItemToJson(g);
    });


    let costsByStratGroup1 = [];
    let costsByStratGroup2 = [];
    let costsByCompGroup1 = [];
    let costsByCompGroup2 = [];
    buildDataInGroups(costsByStrat, costsByStratGroup1, costsByStratGroup2);
    buildDataInGroups(costsByComp, costsByCompGroup1, costsByCompGroup2);

    const chartDataStrat = compareSimulations.length > 0 ? buildComparativeData(compareSimulations, costsByStrat) : costsByStrat;
    const chartDataStratGroup1 = compareSimulations.length > 0 ? buildComparativeData(filterByStrategy(compareSimulations, true), costsByStratGroup1) : costsByStratGroup1;
    const chartDataStratGroup2 = compareSimulations.length > 0 ? buildComparativeData(filterByStrategy(compareSimulations, true), costsByStratGroup2) : costsByStratGroup2;
    const chartDataComp = compareSimulations.length > 0 ? buildComparativeData(compareSimulations, costsByComp) : costsByComp;
    const chartDataCompGroup1 = compareSimulations.length > 0 ? buildComparativeData(filterByStrategy(compareSimulations, false), costsByCompGroup1) : costsByCompGroup1;
    const chartDataCompGroup2 = compareSimulations.length > 0 ? buildComparativeData(filterByStrategy(compareSimulations, false), costsByCompGroup2) : costsByCompGroup2;


    const tableUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/marginalCosts?' + buildRangeParameters(range) + '&aggregation=' + groupBy + '&mode=competitive';
    const dataTableComp =[...chartDataComp].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))}));
    const table =  groupBy === 'Hour' && chartDataComp && chartDataComp.length > 0 ? (
        <TablePagination title={"Marginal Costs (Competitive)"} columns={buildColumnsFromData(costsByComp)} url={tableUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) :
        <BasicTable title={"Marginal Costs (Competitive)"} data={dataTableComp} columns={buildColumnsFromData(costsByComp)} options={{
            fixedHeaderScrollHeight: "350px"
        }}/>;

    const tableStrategicUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/marginalCosts?' + buildRangeParameters(range) + '&aggregation=' + groupBy + '&mode=strategic';
    const dataTableStrat =[...chartDataStrat].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))}));
    const tableStrategic = groupBy === 'Hour' && chartDataStrat && chartDataStrat.length > 0 ? (
        <TablePagination title={"Marginal Costs (Strategic)"} columns={buildColumnsFromData(chartDataStrat)} url={tableStrategicUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) :
        <BasicTable title={"Marginal Costs (Strategic)"} data={dataTableStrat} columns={buildColumnsFromData(costsByStrat)} options={{
            fixedHeaderScrollHeight: "350px"
        }}/>;

    const subChartsGroup1 = {
        'CCGT': [],
        'Coal': [],
        'Imp/Exp': [],
        'Nuclear': []
    };
    const subChartsStratGroup1 = {
        'CCGT': [],
        'Coal': [],
        'Imp/Exp': [],
        'Nuclear': []
    };
    const subKeysGroup1 = ['CCGT', 'Coal', 'Imp/Exp', 'Nuclear'];
    const subChartsGroup2 = {
        'Solar PV': [],
        'Solar Thermal': [],
        'Wind': [],
        'Other renewables': []
    };
    const subChartsStratGroup2 = {
        'Solar PV': [],
        'Solar Thermal': [],
        'Wind': [],
        'Other renewables': []
    };
    const subKeysGroup2 = ['Solar PV', 'Solar Thermal', 'Wind', 'Other renewables'];
    if(chartDataCompGroup1.length > 0){
        buildSubGroupsData(subKeysGroup1, chartDataCompGroup1, subChartsGroup1);
    }
    if(chartDataCompGroup2.length > 0){
        buildSubGroupsData(subKeysGroup2, chartDataCompGroup2, subChartsGroup2);
    }
    if(chartDataStratGroup1.length > 0){
        buildSubGroupsData(subKeysGroup1, chartDataStratGroup1, subChartsStratGroup1);
    }
    if(chartDataStratGroup2.length > 0){
        buildSubGroupsData(subKeysGroup2, chartDataStratGroup2, subChartsStratGroup2);
    }

    const miniChartStyles = {width:"50%", height: "500px", float: "left", marginBottom: "10px"}

    let tabStrategicVsCompetitive = null;
    if(['Year', 'All'].includes(groupBy) && strategy && subChartsGroup1['CCGT'].length > 0){
        let subChartsGroup1All = {}
        let subChartsGroup2All = {}

        Object.keys(subChartsGroup1).forEach(k => {
            let aggByKey = {}
            Object.keys(subChartsGroup1[k][0]).forEach(k1 => {
                if(k1 !== 'time'){
                    aggByKey[k1 + '[C]'] = subChartsGroup1[k][0][k1];
                    aggByKey[k1 + '[S]'] = subChartsStratGroup1[k][0][k1];
                }
            });
            subChartsGroup1All[k] = [aggByKey];
        });
        Object.keys(subChartsGroup2).forEach(k => {
            let aggByKey = {}
            Object.keys(subChartsGroup2[k][0]).forEach(k1 => {
                if(k1 !== 'time'){
                    aggByKey[k1 + '[C]'] = subChartsGroup2[k][0][k1];
                    aggByKey[k1 + '[S]'] = subChartsStratGroup2[k][0][k1];
                }
            });
            subChartsGroup2All[k] = [aggByKey];
        });

        tabStrategicVsCompetitive = <Tab eventKey={5} title="Competitive vs Strategic">
                                        {
                                            Object.keys(subChartsGroup1All).map(k=>
                                                <div key={"g1-all-" +k} style={miniChartStyles}>
                                                    <AreaChartWrapper data={subChartsGroup1All[k]}
                                                                      stackMode={true}
                                                                      aggregation={groupBy}
                                                                      title={'Marginal Costs '+k+' (Competitive)'} brushKey="time" xAxisKey="time" yAxisUnit="€/MWh" />
                                                </div>
                                            )
                                        }
                                        {
                                            Object.keys(subChartsGroup2All).map(k=>
                                                <div key={"g2-all-" +k} style={miniChartStyles}>
                                                    <AreaChartWrapper data={subChartsGroup2All[k]}
                                                                      stackMode={true}
                                                                      aggregation={groupBy}
                                                                      title={'Marginal Costs '+k+' (Competitive)'} brushKey="time" xAxisKey="time" yAxisUnit="€/MWh" />
                                                </div>
                                            )
                                        }
                                    </Tab>
    }

    const tabsByMode = tabMode === "charts" ? (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive)">
                {
                    Object.keys(subChartsGroup1).map(k=>
                        <div key={"g1" +k} style={miniChartStyles}>
                            <AreaChartWrapper data={subChartsGroup1[k]}
                                              stackMode={true}
                                              aggregation={groupBy}
                                              title={'Marginal Costs '+k+' (Competitive)'} brushKey="time" xAxisKey="time" yAxisUnit="€/MWh" />
                        </div>
                    )
                }
                {
                    Object.keys(subChartsGroup2).map(k=>
                        <div key={"g2" +k} style={miniChartStyles}>
                            <AreaChartWrapper data={subChartsGroup2[k]}
                                              stackMode={true}
                                              aggregation={groupBy}
                                              title={'Marginal Costs '+k+' (Competitive)'} brushKey="time" xAxisKey="time" yAxisUnit="€/MWh" />
                        </div>
                    )
                }
            </Tab>
            <Tab eventKey={2} title="Duration (Competitive)">
                {
                    Object.keys(subChartsGroup1).map(k=>
                        <div key={"g1_duration" +k} style={miniChartStyles}>
                            <LineChartWrapper data={subChartsGroup1[k]}
                                              aggregation={groupBy}
                                              title={'Marginal Costs '+k+' (Competitive)'}  xAxisKey="index" yAxisUnit="€/MWh" />
                        </div>)
                }
                {
                    Object.keys(subChartsGroup2).map(k=>
                        <div key={"g2_duration" +k} style={miniChartStyles}>
                            <LineChartWrapper data={subChartsGroup2[k]}
                                              aggregation={groupBy}
                                              title={'Marginal Costs '+k+' (Competitive)'}  xAxisKey="index" yAxisUnit="€/MWh" />
                        </div>
                    )
                }
            </Tab>
            {strategy &&
                <Tab eventKey={3} title="Chronological (Strategic)">
                    {
                        Object.keys(subChartsStratGroup1).map(k=>
                            <div key={"g3" +k} style={miniChartStyles}>
                                <AreaChartWrapper data={subChartsStratGroup1[k]}
                                                  stackMode={true}
                                                  aggregation={groupBy}
                                                  title={'Marginal Costs '+k+' (Strategic)'} brushKey="time" xAxisKey="time" yAxisUnit="€/MWh" />
                            </div>
                        )
                    }
                    {
                        Object.keys(subChartsStratGroup2).map(k=>
                            <div key={"g4" +k} style={miniChartStyles}>
                                <AreaChartWrapper data={subChartsStratGroup2[k]}
                                                  stackMode={true}
                                                  aggregation={groupBy}
                                                  title={'Marginal Costs ' + k +' (Strategic)'} brushKey="time" xAxisKey="time" yAxisUnit="€/MWh" />
                            </div>
                        )
                    }
                </Tab>
            }
            {strategy &&
                <Tab eventKey={4} title="Duration (Strategic)">

                    {
                        Object.keys(subChartsStratGroup1).map(k=>
                            <div key={"g2_duration_str" +k} style={miniChartStyles}>
                                <AreaChartWrapper data={subChartsStratGroup1[k]}
                                                  stackMode={true}
                                                  aggregation={groupBy}
                                                  title={'Marginal Costs '+k+' (Strategic)'} brushKey="time" xAxisKey="index" yAxisUnit="€/MWh" />
                            </div>
                        )
                    }
                    {
                        Object.keys(subChartsStratGroup2).map(k=>
                            <div key={"g2_duration_str" +k} style={miniChartStyles}>
                                <AreaChartWrapper data={subChartsStratGroup2[k]}
                                                  stackMode={true}
                                                  aggregation={groupBy}
                                                  title={'Marginal Costs '+k+' (Strategic)'} brushKey="time" xAxisKey="index" yAxisUnit="€/MWh" />
                            </div>
                        )
                    }
                </Tab>
            }
            {tabStrategicVsCompetitive}

        </Tabs>
    ) : (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Competitive ">
                {table}
            </Tab>
            {strategy &&
                <Tab eventKey={2} title="Strategic ">
                    {tableStrategic}
                </Tab>
            }
        </Tabs>
    );


    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <div className="recharts-component" style={{height:"2650px", marginBottom: "15px"}}>
                <ChartTableToggle mode={tabMode} updateSelectedTab={setTabSelected} updateTabMode={setTabMode}/>
                <Row>
                    <Col sm={12}>
                        {tabsByMode}
                    </Col>
                </Row>
            </div>
        </ErrorBoundary>
    );
}

export default MarginalCostsOutput;
