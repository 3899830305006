import {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import {Alert, Col, ListGroup, ProgressBar, Row} from "react-bootstrap";
import {FaBan, FaCheck, FaRegClock, FaRunning, FaStopwatch} from "react-icons/fa";
import socket from '../common/sockets'
import {processMessage, sendNotification} from "../commons";
import moment from "moment";

function getDuration(messagesByKey) {
    if("duration" in messagesByKey[1]){
        return Math.trunc(messagesByKey[1]["duration"] / 1000);
    }
    const startedAt = messagesByKey[0]["processTime"];
    const finishedAt = messagesByKey[1]["processTime"];
    const startedDate = moment(startedAt, "dd/MM/yyyy HH:mm:ss");
    const finishDate = moment(finishedAt, "dd/MM/yyyy HH:mm:ss");
    return (finishDate - startedDate) / 1000;
}

function getTotalTime(messages) {
    const startedAt = messages[0]["processTime"];
    const finishedAt = messages[messages.length - 1]["processTime"];
    const startedDate = moment(startedAt, "dd/MM/yyyy HH:mm:ss");
    const finishDate = moment(finishedAt, "dd/MM/yyyy HH:mm:ss");
    return Math.trunc((finishDate - startedDate) / 1000);
}

const SimulationProgressMessages = ({ simulationId, isStrategy, userId }) => {

    const [messages, setMessages] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const [socketOn, setSocketOn] = useState(false);

    useEffect(() => {
        if(!socketOn && userId) {
            setSocketOn(true);
            socket.on(userId, function (data) {
                processMessage(data);
                if (data.event === 'run_simulation' && parseInt(data.simId) === parseInt(simulationId)) {
                    setMessages(messages => [
                        ...messages,
                        {...data}
                    ]);
                    if (data.method === 'Simulation Done') {
                        window.location.reload();
                        sendNotification("Simulation finished", "success");
                    }
                }
            });
        }

        return () => {
            setMessages([]);
            setPercentage(0)
        };
        // eslint-disable-next-line
    }, [userId, simulationId]);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationId + "/progress"))
            .then(res => res.json()).then(data => {
                setMessages(data.messages);
            });
        // eslint-disable-next-line
    }, [simulationId, userId]);


    useEffect(() => {
        const totalSteps = isStrategy ? 27 : 25;
        if(messages.length > 0) {
            setPercentage(parseInt((100 * messages.length) / totalSteps));
        }else{
            setPercentage(0);
        }
    }, [messages, isStrategy]);

    let simulationInfo = [];
    if(messages && messages.length > 0){
        let uniqueMessagesKey = []
        messages.forEach(m=>{
            if(!uniqueMessagesKey.includes(m.message)) {
                uniqueMessagesKey.push(m.message);
            }
        });
        uniqueMessagesKey.forEach(k=>{
           const messagesByKey = messages.filter(m=>m.message === k);
           if (messagesByKey.length === 2 && messagesByKey.filter(m=>m.status === 'Success').length === 2){
               const finishedAt = messagesByKey[1]["processTime"];
               const secondsDuration = getDuration(messagesByKey);
               simulationInfo.push({
                   "processTime": finishedAt,
                   "method": messagesByKey[1]["method"],
                   "message": messagesByKey[1]["message"],
                   "duration": secondsDuration,
                   "status": "Success"
               });
           }
           else if (messagesByKey.length === 2 && messagesByKey.filter(m=>m.status === 'Success').length !== 2){
               const finishedAt = messagesByKey[1]["processTime"];
               const secondsDuration = getDuration(messagesByKey);
               simulationInfo.push({
                    "processTime": finishedAt,
                    "method": messagesByKey[1]["method"],
                    "message": messagesByKey[1]["message"],
                    "duration": secondsDuration,
                    "status": "Fail"
                });
           }
           else if (messagesByKey.length === 1 && messagesByKey.filter(m=>m.status === 'Success').length === 1){
               let isFinished = messagesByKey[0]["message"].includes("Finished");
               simulationInfo.push({
                   "processTime": messagesByKey[0]["processTime"],
                   "method": messagesByKey[0]["method"],
                   "message": messagesByKey[0]["message"],
                   "duration": isFinished ? getTotalTime(messages) : 0,
                   "status": isFinished ? "Finished" : "Running"
               });
           }
        });
    }

    return simulationInfo.length === 0 ?
        <Alert key={"no-items-progress"} variant={"info"}>
            No messages for this simulation
        </Alert>
        :
        <div>
            <ListGroup variant="flush">
                {
                    simulationInfo.map((m, idx) => {
                        let icon = null;
                        let message = null;
                        switch (m.status){
                            case "Finished":
                            case "Success": icon = <FaCheck style={{color:"green"}}/>;
                                            message = <div>
                                                        <span>{m.method}</span>
                                                        <span className={"float-right"}>
                                                                <FaStopwatch/> {m.duration} secs
                                                            </span>
                                                    </div>
                                            break;
                            case "Fail": icon = <FaBan style={{color:"red"}}/>;
                                         message = <code>{m.method + ' - ' + m.message}</code>
                                         break;
                            case "Running": icon = <FaRunning style={{color:"blue"}}/>;
                                            message = <div>
                                                <span>{m.method}</span>
                                            </div>
                                            break;
                            default:        break;
                        }
                        return <ListGroup.Item key={idx}>
                            <Row>
                                <Col>
                                    <FaRegClock style={{marginRight: "5px"}}/>
                                    {m.processTime}
                                </Col>
                                <Col xs={5}>
                                    {message}
                                </Col>
                                <Col className={"text-center"}>
                                    {icon}
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    })
                }
            </ListGroup>
            <ProgressBar style={{marginTop: "40px"}} now={percentage} label={`${percentage}%`} />
        </div>

}

export default SimulationProgressMessages