import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import "react-datepicker/dist/react-datepicker.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {usePromiseTracker} from "react-promise-tracker";

import Notification from "react-notify-bootstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import {NotificationDataProvider} from "./components/common/context";

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();



    return (
        promiseInProgress &&
        <div style={{
                    top:0,
                    left: 0,
                    position: "absolute",
                    opacity: 0.6,
                    backgroundColor: "#CECECE",
                    zIndex: 9999,
                    width: "100%",
                    height: 1200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
              }}>
            <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <PropagateLoader color="#174585" loading={promiseInProgress} size={20} />
            </div>
        </div>
);
};

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
      <NotificationDataProvider>
        <App/>
          <Notification options={{
            position: "top",
            delay: 10000
          }}/>
        <LoadingIndicator/>
      </NotificationDataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
