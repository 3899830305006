import React, {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import DemandDataChart from "../charts/DemandDataChart";
import InterconnectionDataChart from "../charts/InterconnectionDataChart";
import RenewableDataChart from "../charts/RenewableDataChart";
import BasicTable from "../common/BasicTable";
import HydroDataChart from "../charts/HydroDataChart";
import FuelDataChart from "../charts/FuelDataChart";
import PriceDataChart from "../charts/PriceDataChart";
import TechnologyGenerationDataChart from "../charts/TechnologyGenerationDataChart";


const maintenanceColumns = [
    {
        name: "Company Code",
        selector: row => row.code
    },
    {
        name: "Start Date",
        selector: row => row.start_date
    },
    {
        name: "End Date",
        selector: row => row.end_date
    }
];


function DataSeriesChart({dataSelected}) {

    const [dataChart, setDataChart] = useState(null);


    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/data/series/' + dataSelected.id + '/' + dataSelected.type, {
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }))
            .then(res => res.json())
            .then(data => {
                switch (dataSelected.type) {
                    case "demand":
                        setDataChart(<DemandDataChart data={data.data} all={data.all}></DemandDataChart>);
                        break;
                    case "interconnection":
                        setDataChart(<InterconnectionDataChart data={data.data} all={data.all}></InterconnectionDataChart>);
                        break;
                    case "renewable":
                        setDataChart(<RenewableDataChart data={data.data} all={data.all}></RenewableDataChart>);
                        break;
                    case "maintenance":
                        setDataChart(<BasicTable title="Maintenance" columns={maintenanceColumns} data={data.data}></BasicTable>);
                        break;
                    case "hydro":
                        setDataChart(<HydroDataChart data={data.data} all={data.all}></HydroDataChart>);
                        break;
                    case "fuel":
                        setDataChart(<FuelDataChart data={data.data} all={data.all}></FuelDataChart>);
                        break;
                    case "price":
                        setDataChart(<PriceDataChart data={data.data} all={data.all}></PriceDataChart>);
                        break;
                    case "technology_generation":
                        setDataChart(<TechnologyGenerationDataChart data={data.data} all={data.all}></TechnologyGenerationDataChart>);
                        break;
                    default:
                        break;
                }
            });
    }, [dataSelected]);



    return (
        <div className={"dataSeriesChart"}>
            {dataChart}
        </div>
    );
}

export default DataSeriesChart;
