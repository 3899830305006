import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { trackPromise } from 'react-promise-tracker';
import DataTypeItem from "./DataTypeItem";
import DataSeriesChart from "./DataSeriesChart";
import {getDomainByLocation, getIconByDataType, sendNotification} from "../commons";
import icon_data_series from "../../img/Icon Data Series.png"
import bg from "../../img/Background sections-01.png"
import socket from "../common/sockets";

function DataSeries({ user, username }) {
    const [show, setShow] = useState(false);
    const [dataTypes, setDataTypes] = useState([]);
    const [dataSeriesList, setDataSeriesList] = useState([]);
    const [dataSelected, setDataSelected] = useState(null);
    const [dataSeriesToDelete, setDataSeriesToDelete] = useState(null);
    const [socketOn, setSocketOn] = useState(false);

    const deleteDataSeries = useCallback(
        event => {
            event.preventDefault();
            setDataSeriesToDelete(event.target.id);
            setShow(true);
        },
        []
    );

    const defaultDataSeries = useCallback(
        event => {
            event.preventDefault();
            let dataSeriesId = event.target.id;
            trackPromise(
                fetch('/m40alasocho/data/series/' + dataSeriesId + '/default', { method: 'PUT' }))
                .then(res => res.json()).then(data => {
                    handleClose();
                    setDataSelected(Object.assign({}, dataSelected, { isDefault: true }));
                    setDataTypes(data.list);
                    sendNotification('Data Series set as default', "success");
                });
        },
        [dataSelected]
    );

    useEffect(()=>{
        if(!socketOn && username) {
            setSocketOn(true);
            socket.on(username, function (data) {
                if (data.event === "run_simulation" && data.message === 'Simulation Done') {
                    sendNotification(data.step, "success");
                }
            });
        }
        // eslint-disable-next-line
    }, [username]);

    const handleClose = () => setShow(false);

    const confirmDeleteDataSeries = () => {
        trackPromise(
            fetch('/m40alasocho/data/series/' + dataSeriesToDelete + '/' + dataSelected.type, { method: 'DELETE' }))
            .then(res => res.json()).then(data => {
                if(data.status === 'error'){
                    sendNotification(data.message, "warning");
                    handleClose();
                }else {
                    setDataSelected(null);
                    setDataSeriesToDelete(null);
                    handleClose();
                    setDataTypes(data.list);
                    sendNotification('Data Series deleted', "success");
                }
            });
    };


    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/data/series/user/' + user.id))
            .then(res => res.json())
            .then(data => {
                setDataTypes(data.list);
            });
    }, [user]);

    useEffect(()=>{
        document.getElementById('bodyId').style.backgroundImage = 'none';
    }, []);

    const refreshDataTypes = (data) => {
        setDataTypes(data.list);
        setDataSelected({
            id: data.dataType.id,
            type: data.dataType.type,
            name: data.dataType.name,
            importDate: data.dataType.import_date
        });
    };

    const loadDataType = (fileDataId, dataType) => {
        document.getElementsByClassName("modal-right col-sm-10")[0].style.backgroundImage = 'none';
        let selectedFile = dataType.files.filter(f => f.id === parseInt(fileDataId))[0];
        setDataSelected({
            id: fileDataId,
            type: dataType.type,
            name: selectedFile.name,
            importDate: selectedFile.import_date,
            isDefault: selectedFile.is_default
        });
    };

    useEffect(()=>{
        document.getElementById('bodyId').style.backgroundImage = 'none';
    }, []);

    useEffect(() => {
        setDataSeriesList(dataTypes && dataTypes.length > 0 ? (
            <div className="text-left">
                <Alert variant="primary" className="button-primary-cover">
                    <Row>
                                <Col sm={3}>
                                    <img src={icon_data_series} alt="Simulations" style={{ height: "40px" }}></img>
                                </Col>
                                <Col>
                                    <p>
                                    DATA SERIES
                                    </p>
                        </Col>
                    </Row>
                </Alert>
                <div className="data-series-left">
                    {dataTypes.map((item, index) => {
                        return (<DataTypeItem dataType={item} key={index}
                            loadDataTypeCallback={loadDataType}
                            user={user}
                            refreshDataTypesCallback={refreshDataTypes}
                        />);
                    }
                    )}
                </div>
            </div>
        ) :
            <div className="text-left">
                <Alert variant="primary" className="button-primary-cover">
                            <Row>
                                <Col sm={2}>
                                    <img src={icon_data_series} alt="Simulations" style={{ height: "40px" }}></img>
                                </Col>
                                <Col>
                                    <p>
                                    Please upload a Data Series
                                    </p>
                                </Col>
                            </Row>
                        </Alert>
            </div>);
    }, [dataTypes, user]);

    let dataTypeTitle = getIconByDataType(dataSelected);

    const isDefaultBtn = dataSelected && !dataSelected.isDefault ? <button type="button" className="btn btn-sm btn-outline-success float-right" style={{ marginRight: "5px" }} onClick={defaultDataSeries} id={dataSelected.id}>Default</button> :
        null;

    const domain = getDomainByLocation();
    const downloadLink = dataSelected != null ? (domain + '/m40alasocho/data/series/' + dataSelected.id + '/download')
        : null;
    const legend = dataSelected != null ? (<Alert variant="primary" style={{ marginTop: "10px" }}>
        <Row>
            <Col sm={9}>
                <Row>
                    <Col sm={4}>
                        <b>
                            {dataTypeTitle}
                        </b>
                    </Col>
                    <Col sm={4}>
                        <b>File Name: </b>
                        {dataSelected.name}
                    </Col>
                    <Col sm={4}>
                        <b>Import Date: </b>
                        {dataSelected.importDate.join(" ")}
                    </Col>
                </Row>
            </Col>
            <Col sm={3}>
                <button type="button" className="btn btn-sm btn-outline-danger float-right" style={{ marginRight: "5px" }} onClick={deleteDataSeries} id={dataSelected.id}>Delete</button>
                {isDefaultBtn}
                <a target="_blank" href={downloadLink} rel="noreferrer">
                    <button type="button" className="btn btn-sm btn-outline-primary float-right" style={{ marginRight: "5px" }}>Download</button>
                </a>
            </Col>
        </Row>
    </Alert>) : null;

    const dataSelectedChart = dataSelected ? <DataSeriesChart dataSelected={dataSelected} /> : null;

    const welcomeView = dataSelected == null ? (

        <Container className='filler'>
            <Row>
                <Col md={{ span: 9, offset: 1 }}>
                    <h1>
                        DATA SERIES
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 9, offset: 1 }}>
                    <p >
                        The data series section contains information about electricity demand, renewable energy availability, fossil-fuel prices, and interconnection prices in your electricity market.
                        You can visualize the data series by clicking on one of the files on your left.
                        You can also upload files and select the default files for your simulations.
                    </p>
                </Col>
            </Row>
        </Container>
    ) : null;


    return (
        <Container fluid>
            <Row>
                <Col sm={2} className="modal-left">
                    <div style={{ marginTop: "10px" }}>
                        {dataSeriesList}
                    </div>
                </Col>
                <Col sm={10} className="modal-right" style={{ backgroundImage: `url('${bg}')` }}>
                    <div style={{ width: "100%"}}>
                        {legend}
                        {dataSelectedChart}
                        {welcomeView}
                    </div>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Data Series</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to delete this data series?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={confirmDeleteDataSeries}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default DataSeries;
