import React, {useEffect, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {trackPromise} from "react-promise-tracker";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import AreaChartWrapper from "../common/AreaChartWrapper";
import {
    addTotals,
    buildColumnsFromData,
    buildComparativeData,
    buildRangeParameters,
    convertToMomentTz,
    deleteInterConnections,
    divideUnitsBy,
    filterByStrategy,
    getDateFormatByAggregation,
    sendNotification,
    sortTechnologies
} from "../commons";
import ChartTableToggle from "../common/ChartTableToggle";
import TablePagination from "../common/TablePagination";
import BasicTable from "../common/BasicTable";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

function ProfitOutput({simulationId, aggregation, range, compareWith,
    groupBy, strategy}) {

    const [prices, setPrices] = useState([]);
    const [compareSimulations, setCompareSimulations] = useState([]);
    const [tabMode, setTabMode] = useState("charts");
    const [tabSelected, setTabSelected] = useState(1);

    useEffect(()=>{

    }, [tabMode, tabSelected]);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationId + '/results/profit'
                + aggregation + '?' + buildRangeParameters(range)
                + '&aggregation='+ groupBy
                + '&allData=' + ((compareWith.length > 0) ? '1' : '0')))
            .then(res => res.json()).then(data => {
            setPrices(data["data"]);
        });
    }, [simulationId, aggregation, range, groupBy, compareWith]);

    useEffect(() => {
        if(compareWith.length > 0) {
            if(groupBy === 'All') {
                compareWith.forEach(c => {
                    trackPromise(
                        fetch('/m40alasocho/simulation/' + c + '/results/profit'
                            + aggregation + '?' + buildRangeParameters(range)
                            + '&aggregation=' + groupBy
                            + '&allData=' + ((compareWith.length > 0) ? '1'
                                : '0')))
                    .then(res => res.json()).then(data => {
                        if (compareSimulations.filter(
                            cs => cs["simId"] === c).length === 0) {
                            setCompareSimulations(comp => [...comp, {
                                "data": [...data["data"]],
                                "simId": c
                            }]);
                        } else {
                            setCompareSimulations(comp => comp.map(cs => {
                                if (cs["simId"] !== c) {
                                    return cs;
                                } else {
                                    return {
                                        ...cs,
                                        "data": [...data["data"]]
                                    }
                                }
                            }));
                        }
                    });
                });
                setCompareSimulations(
                    cs => cs.filter(c => compareWith.includes(c["simId"])));
            }
            else{
                sendNotification("This view can only be compared under Simulation Period", "info");
                setCompareSimulations([]);
            }
        }else{
            setCompareSimulations([]);
        }
        // eslint-disable-next-line
    }, [ aggregation, range, compareWith, groupBy]);


    const byStrat = prices.filter(g=>g["strategy"]).map(g=> {
        if(groupBy !== 'All' && "data" in g) {
            const data = sortTechnologies(JSON.parse(g["data"]), aggregation);
            let out = {
                time: g["entryDate"],
                ...data
            }
            deleteInterConnections(out, ["MAR", "POR", "FRA", "Exp", "Imp", "Exp/Imp", "Imp/Exp"]);
            return out;
        }else{
            let out = {...g}
            delete out.strategy;
            let data = sortTechnologies(out, aggregation);
            deleteInterConnections(data, ["MAR", "POR", "FRA", "Exp", "Imp", "Exp/Imp", "Imp/Exp"]);
            return data;
        }
    });
    const byComp = prices.filter(g=>!g["strategy"]).map(g=> {
        if(groupBy !== 'All' && "data" in g) {
            let data = sortTechnologies(JSON.parse(g["data"]), aggregation);
            let out = {
                time: g["entryDate"],
                ...data
            }
            deleteInterConnections(out, ["MAR", "POR", "FRA", "Exp", "Imp", "Exp/Imp", "Imp/Exp"]);
            return out;
        }else{
            let out = {...g}
            delete out.strategy;
            let data = sortTechnologies(out, aggregation);
            deleteInterConnections(data, ["MAR", "POR", "FRA", "Exp", "Imp", "Exp/Imp", "Imp/Exp"]);
            return data;
        }
    });


    let chartDataStrategic = [], chartDataCompetitive = [];
    if(byStrat.length > 0) {
        chartDataStrategic = compareSimulations.length > 0 ? buildComparativeData(filterByStrategy(compareSimulations, true), byStrat) : byStrat;
    }
    if(byComp.length > 0) {
        chartDataCompetitive = compareSimulations.length > 0 ? buildComparativeData(filterByStrategy(compareSimulations, false), byComp) : byComp;
    }

    const tableUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/profit' + aggregation + '?' + buildRangeParameters(range) + '&aggregation=' + groupBy + '&mode=strategic';
    const dataTableStrat =[...chartDataStrategic].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))}));
    const tableStrategic = groupBy === 'Hour' && chartDataStrategic && chartDataStrategic.length > 0 ? (
        <TablePagination title={"Chronological (Strategic)"}  columns={buildColumnsFromData(chartDataStrategic)} url={tableUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) : <BasicTable title={"Chronological (Strategic)"} data={addTotals(dataTableStrat)} columns={buildColumnsFromData(addTotals(dataTableStrat))} options={{
            fixedHeaderScrollHeight: "350px"
        }}/>;

    const tableCompetitiveUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/profit' + aggregation + '?' + buildRangeParameters(range) + '&aggregation=' + groupBy + '&mode=competitive';
    const dataTableComp =[...chartDataCompetitive].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))}));
    const tableCompetitive = groupBy === 'Hour' && chartDataCompetitive && chartDataCompetitive.length > 0 ? (
        <TablePagination title={"Chronological (Competitive)"} columns={buildColumnsFromData(chartDataCompetitive)} url={tableCompetitiveUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) : <BasicTable title={"Chronological (Competitive)"} data={addTotals(dataTableComp)}
                    columns={buildColumnsFromData(addTotals(dataTableComp))} options={{
        fixedHeaderScrollHeight: "350px"
    }}/>;

    const chartDataStrategicMap = divideUnitsBy(chartDataStrategic, 1000000);
    const chartDataCompetitiveMap = divideUnitsBy(chartDataCompetitive, 1000000);

    let tabStrategicVsCompetitive = null;
    if(['Year', 'All'].includes(groupBy) && strategy && chartDataCompetitiveMap.length > 0){
        let aggregatedObject = {}
        Object.keys(chartDataCompetitiveMap[0]).forEach(k => {
            if(k !== 'time'){
                aggregatedObject[k + '[C]'] = chartDataCompetitiveMap[0][k];
            }
        });
        Object.keys(chartDataStrategicMap[0]).forEach(k => {
            if(k !== 'time') {
                aggregatedObject[k + '[S]'] = chartDataStrategicMap[0][k];
            }
        });

        tabStrategicVsCompetitive = <Tab eventKey={5} title="Competitive vs Strategic">
            <AreaChartWrapper data={[aggregatedObject]}
                              title={"Competitive vs Strategic"}
                              aggregation={groupBy}
                              xAxisKey="index" yAxisUnit="M€"/>
        </Tab>
    }

    const tabsByMode = tabMode === "charts" ? (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive) ">
                <AreaChartWrapper data={chartDataCompetitiveMap} title={"Profits by " + aggregation +" (Competitive)"}
                                  aggregation={groupBy}
                                  xAxisKey="time" brushKey="time" yAxisUnit="M€" />
            </Tab>
            <Tab eventKey={2} title="Duration (Competitive) ">
                <AreaChartWrapper data={chartDataCompetitiveMap} title={"Profits by " + aggregation +" (Competitive)"}
                                  xAxisKey="index"
                                  yAxisUnit="M€" />
            </Tab>
            {strategy &&
                <Tab eventKey={3} title="Chronological (Strategic) ">
                    <AreaChartWrapper data={chartDataStrategicMap}
                                      title={"Profits by " + aggregation
                                          + " (Strategic)"}
                                      aggregation={groupBy}
                                      xAxisKey="time" brushKey="time"
                                      yAxisUnit="M€"/>
                </Tab>
            }
            {strategy &&
                <Tab eventKey={4} title="Duration (Strategic) ">
                    <AreaChartWrapper data={chartDataStrategicMap}
                                      title={"Profits by " + aggregation
                                          + " (Strategic)"}
                                      aggregation={groupBy}
                                      xAxisKey="index" yAxisUnit="M€"/>
                </Tab>
            }
            {tabStrategicVsCompetitive}
        </Tabs>
    ) : (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive) ">
                {tableCompetitive}
            </Tab>
            {strategy &&
                <Tab eventKey={2} title="Chronological (Strategic) ">
                    {tableStrategic}
                </Tab>
            }
        </Tabs>
    );

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <div className="recharts-component">
                <ChartTableToggle mode={tabMode} updateSelectedTab={setTabSelected} updateTabMode={setTabMode}/>
                <Row>
                    <Col sm={12}>
                        {tabsByMode}
                    </Col>
                </Row>
            </div>
        </ErrorBoundary>
    );
}

export default ProfitOutput;
