import React, {useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import {trackPromise} from "react-promise-tracker";
import {convertToMomentTz, formatNumbersDecimals} from "../commons";

function convertDataToMoment(data, transformations) {
  const rows = data && data.map(p=>{
    let d = {
      ...p,
      "time": p.entryDate,
    };
    if ("data" in d){
      d = {
        ...d
      }
      if(typeof d["data"] == 'object'){
        d = {
          ...d,
          ...d["data"]
        }
      }else{
        d = {
          ...d,
          ...JSON.parse(p["data"])
        }
      }
    }
    delete d.entryDate;
    return d;
  });
  const results = [...rows].map(d=>({...d, "time": convertToMomentTz(d.time).format('DD-MM-YYYY' +
        ' HH:mm:ss')}));
  if(transformations){
    return results.map(d=>{
      let out = {};
      Object.keys(d).forEach(k=>{
        if(!isNaN(d[k])){
          if(transformations["method"] === 'divideUnitsBy') {
            out[k] = d[k] / 1000;
          }
        }else{
          out[k] = d[k];
        }
      })
      return out;
    });
  }
  return results;
}

const TablePagination = ({title, columns, url, options, transformations}) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const defaultOpts = {
    fixedHeaderScrollHeight: "250px",
  };

  const props = options ? options : defaultOpts;

  const fetchData = async page => {
    setLoading(true);

    trackPromise(
        fetch(`${url}&page=${page}&per_page=${perPage}`)
    ).then(res => res.json()).then(data => {
      const dataRows = convertDataToMoment(data.data, transformations);
      setData(formatNumbersDecimals(dataRows));
      setTotalRows(data.total);
      setLoading(false);
    });
  };

  const handlePageChange = page => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    trackPromise(
        fetch(`${url}&page=${page}&per_page=${newPerPage}`)
    ).then(res => res.json()).then(data => {
      const dataRows = convertDataToMoment(data.data, transformations);
      setData(formatNumbersDecimals(dataRows));
      setPerPage(newPerPage);
      setLoading(false);
    });

  };

  useEffect(() => {
    fetchData(1); // fetch page 1 of users
    // eslint-disable-next-line
  }, [url]);

  return (
        <div style={{width: "100%"}}>
          <h6>
            {title}
          </h6>
          <div className="ag-theme-alpine" style={{width: "100%", height: 500}}>
            <DataTable
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                {...props}
            />
          </div>
        </div>
  );
};

export default TablePagination;
