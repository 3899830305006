import React from "react";
import AreaChartComponent from "./AreaChartComponent";
import BarChartComponent from "./BarChartComponent";
import {orderKeys} from "../commons";

function AreaChartWrapper({title, data, brushKey, xAxisKey,
  yAxisUnit, yAxisRight, aggregation, stackMode, type}) {

  let chart = null;
  if (aggregation && ['Year', 'All'].includes(aggregation)  && data && data.length === 1){
    let chartData = [];
    let chartBars = {}
    const ordered = orderKeys(data[0]);
    Object.keys(ordered).forEach(k=>{
      if(k !== 'time'){
        chartData.push({
          [k]: data[0][k],
          "name": k
        });
        chartBars[k] = k;
      }
    });
    chart = <BarChartComponent title={title} data={chartData} bars={chartBars} xAxisKey={"name"} yAxisUnit={yAxisUnit} />;
  } else {
    chart = <AreaChartComponent title={title} data={data}
                                brushKey={brushKey} xAxisKey={xAxisKey}
                                yAxisUnit={yAxisUnit}
                                stackMode={stackMode}
                                type={type}
                                aggregation={aggregation}
                                yAxisRight={yAxisRight}/>
  }

    return (
            <div className="recharts-component">
              {chart}
            </div>
    )
}
export default AreaChartWrapper;
