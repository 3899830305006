export const msalConfig = {
    auth: {
        clientId: "",
        authority: "https://login.microsoftonline.com/",
        redirectUri: window.location.protocol + "//" + window.location.hostname +":3000/"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};