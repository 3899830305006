import React from "react";
import {Col, Row} from "react-bootstrap";

function HorizontalLine ({ color, size, tittle, icon }) {
    return (
            <Row style={{marginTop: "30px"}}>
                <Col sm={2}>
                    <b style={{float: "left", top: "2px", marginTop: "5px"}}>{icon} {tittle}</b>
                </Col>
                <Col sm={10}>
                    <hr
                        style={{
                            color: color,
                            backgroundColor: color,
                            height: size
                        }}
                    />
                </Col>
            </Row>
    );
};

export default HorizontalLine;