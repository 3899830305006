import React, {forwardRef, useEffect, useState} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {removeFromArray, sendNotification} from "../commons";
import {trackPromise} from "react-promise-tracker";
import moment from "moment-timezone";


function getViewDescription(view){
    switch (view) {
        case "marketPrice": return "This figure shows equilibrium market prices in €/MWh\n" +
                                    "under the competitive and the strategic scenarios"
        case "markups":     return "This figure shows the equilibrium price-cost markups in % (i.e., by how much the equilibrium price departs from the competitive one) under the competitive and the strategic scenarios"
        case "generationByTechnology":  return "This figure shows which generation technologies are used to meet demand under the competitive and the strategic scenarios. The duration curve displays the production of each technology from high to low"
        case "generationByFirm":        return "This figure shows which firms’ generation is used to meet demand under the competitive and the strategic scenarios. The duration curve displays the production of each firm from high to low"
        case "generationByExcess":      return "This figure shows excess renewables by technology under the competitive and the strategic scenarios. The duration curve displays excess renewables by technology from high to low"
        case "priceSetterByFirm":       return "This figure shows which firm is setting the market price under the competitive and the strategic scenarios"
        case "priceSetterByTechnology": return "This figure shows which technology is setting the market price under the competitive and the strategic scenarios"
        case "capturedPriceByFirm":     return "This figure shows the average price captured by firms over the chosen horizon, taking into account the hourly prices and production patterns"
        case "capturedPriceByTechnology":     return "This figure shows the average price" +
            " captured by firms over the chosen horizon, taking into account the hourly prices and production patterns"
        case "marginalCosts":           return "This figure shows the minimum and maximum marginal costs among all the dispatched plants of the same technology\n"
        case "totalCostsByFirm":        return "This figure shows the generation costs of all dispatched plants of the same firm"
        case "totalCostsByTechnology":  return "This figure shows the generation costs of all dispatched plants of the same technology"
        case "profitsByFirm":           return "This figure shows the market profits made by each firm, taking into account the hourly prices at which it sells its output minus the generation costs of all its dispatched plants"
        case "profitsByTechnology":     return "This figure shows the market profits made by each technology, taking into account the hourly prices minus the generation costs of all the dispatched plants of that technology"
        case "paymentsByFirm":          return "This figure shows the market payments made to each firm, taking into account the hourly prices at which it sells times its hourly output"
        case "paymentsByTechnology":    return "This figure shows the market payments made to each technology, taking into account the hourly prices times the hourly output of that technology"
        case "carbonEmissionsByFirm":   return "This figure shows the carbon emissions by firm, taking into account the hourly output times the emission factors of its plants owned by the firm"
        case "carbonEmissionsByTechnology":   return "This figure shows the carbon emissions by technology, taking into account the hourly output times the emission factors of its plants of that technology"
        case "carbonEmissionsBySummary":   return "This figure shows the average per unit carbon content of power generation, and the percentage of total generation which is carbon-free"
        default:            return null;
    }
}

function SimulationFilters({defaultView,
                               simulation,
                               simulationList,
                               updateView,
                               updateResultsPeriod,
                               updateCompareSimulations,
                               updateCompareDataSeries,
                               simulationsToCompare,
                               dataSeriesToCompare,
                               updateAggregation,
                               userId
                           }) {

    const startSim = Date.parse(simulation["configuration"]["startEndSimulation"]["start"]);
    const endSim = Date.parse(simulation["configuration"]["startEndSimulation"]["end"]);

    const rowStyle = {
        marginBottom: "20px"
    };

    const [view, setView] = useState("marketPrice");
    const [simulationMinPeriod, setSimulationMinPeriod] = useState(moment(startSim).tz("GMT").toDate());
    const [simulationMaxPeriod, setSimulationMaxPeriod] = useState(moment(endSim).tz("GMT").toDate());
    const [resultsFrom, setResultsFrom] = useState(startSim);
    const [resultsTo, setResultsTo] = useState(endSim);
    const [compareWithDataSeries, setCompareWithDataSeries] = useState([]);

    useEffect(()=>{
        setSimulationMaxPeriod(endSim);
        setSimulationMinPeriod(startSim);
    },[simulationsToCompare, startSim, endSim, simulationMaxPeriod, simulationMinPeriod,
            dataSeriesToCompare]);

    useEffect(() => {
        if(['marketPrice', 'capturedPriceByFirm', 'capturedPriceByTechnology'].includes(view)) {
            trackPromise(
                fetch('/m40alasocho/data/series/user/' + userId + "/price/compare"))
                .then(res => res.json())
                .then(data => {
                    setCompareWithDataSeries(data.list);
                    updateCompareDataSeries([]);
                });
        }else if(view === 'generationByTechnology') {
            trackPromise(
                fetch('/m40alasocho/data/series/user/' + userId + "/technology_generation/compare"))
                .then(res => res.json())
                .then(data => {
                    setCompareWithDataSeries(data.list);
                    updateCompareDataSeries([]);
                });
        }else{
            setCompareWithDataSeries([]);
        }
        // eslint-disable-next-line
    }, [userId, view, simulation.id, resultsFrom, resultsTo]);


    const availableSimulationsToCompare = simulationList
        .filter(s=>s.id !== simulation.id && s.status === 'COMPLETED').map(s=> {
        return {
            id: s.id,
            name: s.name
        }
    });


    const updateViewExplanation = (view) =>{
        setView(view);
    };

    const groupByOptions = ["Hour", "Date", "Month", "Year", "All"];
    const groupByOptionsMapping = {
        Hour: "Hour",
        Date: "Day",
        Month: "Month",
        Year: "Year",
        All: "Simulation Period"
    }

    const viewDefinition = view && getViewDescription(view)?
        <Row style={rowStyle}>
            <Col sm={12}>
                <Card>
                    <Card.Body>
                        <Card.Text>
                            {getViewDescription(view)}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>: null;

    const PeriodRangeDateButton = forwardRef(({ value, onClick }, ref) => (
        <button className="btn btn-sm btn-outline-info form-control" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const updateRangeFrom = (from)=>{
        setResultsFrom(from);
        updateResultsPeriod([from, resultsTo]);
    };

    const updateRangeTo = (to)=>{
        setResultsTo(to);
        updateResultsPeriod([resultsFrom, to]);
    };

    const updateCompareWithSimulations = (simId, compare) =>{
        if(compare) {
            updateCompareSimulations([...simulationsToCompare, simId]);
        }else{
            updateCompareSimulations(removeFromArray(simulationsToCompare, simId));
        }
    };

    const updateCompareWithDataSeries = (serieId, compare) =>{
        if(compare) {
            updateCompareDataSeries([...dataSeriesToCompare, serieId]);
        }else{
            updateCompareDataSeries(removeFromArray(dataSeriesToCompare, serieId));
        }
    };



    const compareWithSeries = compareWithDataSeries.length > 0 ?
        (
            <Row className={"simulation-filters-left"} style={rowStyle}>
                <Col sm={12}>
                    <span className="filter-title">Compare with Series</span>
                </Col>
                <Col sm={12}>
                    {compareWithDataSeries.map((s, i)=>
                        <Row key={i}>
                            <Col sm={12} className={"ml-3"}>
                                {
                                    s.files.map((f, i) =>
                                        <Form.Check
                                            type="switch"
                                            id={'compare-serie-' + f.id}
                                            key={"custom-switch" + f.id}
                                            data-serie-id={f.id}
                                            checked={dataSeriesToCompare.includes(f.id.toString())}
                                            style={{marginLeft: "0px", float: "left", cursor: "pointer"}}
                                            onChange={(e) => {
                                                if (e.target.checked && dataSeriesToCompare.length === 1) {
                                                    e.target.checked = false;
                                                    sendNotification("Max series to compare is 1", "warning");
                                                } else {
                                                    updateCompareWithDataSeries(e.target.dataset.serieId, e.target.checked);
                                                }
                                            }}
                                            label={f.id + '-' + f.name}
                                        />
                                    )
                                }
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        ) : null;

    const compareWith = availableSimulationsToCompare.length > 0 ?
        (
            <Row style={rowStyle}>
                <Col sm={12}>
                    <span className="filter-title">Compare with</span>
                </Col>
                <Col sm={12}>
                    {availableSimulationsToCompare.map((s, i)=>
                        <Row key={i}>
                            <Col sm={12} className={"ml-4"}>
                                <Form.Check
                                    type="switch"
                                    id={'compare-simulation-' + s.id}
                                    key={"custom-switch" + s.id}
                                    data-simulation-id={s.id}
                                    checked={simulationsToCompare.includes(s.id.toString())}
                                    style={{marginLeft: "0px", float: "left", cursor: "pointer"}}
                                    onChange={(e) => {
                                        if (e.target.checked && simulationsToCompare.length === 2) {
                                            e.target.checked = false;
                                            sendNotification("Max simulations to compare is 3", "warning");
                                        } else {
                                            updateCompareWithSimulations(e.target.dataset.simulationId, e.target.checked);
                                        }
                                    }}
                                    label={s.id + '.' + s.name}
                                />
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        ) : null;

    return (
        <div>
            <Row style={rowStyle}>
                <Col sm={12}>
                    <span className="filter-title">Period</span>
                </Col>
                <Col sm={12} className={"text-left m-1"}>
                    <Row>
                        <Col sm={3}>
                            From
                        </Col>
                        <Col sm={9}>
                            <DatePicker selected={resultsFrom}
                                        className={"margin-left-10"}
                                        onChange={(date) => updateRangeFrom(date)}
                                        dateFormat="dd/MM/yyyy"
                                        closeOnScroll={true}
                                        customInput={<PeriodRangeDateButton />}
                                        maxDate={moment(simulationMaxPeriod).tz("GMT").toDate()}
                                        minDate={moment(simulationMinPeriod).tz("GMT").toDate()} />
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} className={"text-left m-1"}>
                    <Row>
                        <Col sm={3}>
                            To
                        </Col>
                        <Col sm={9}>
                            <DatePicker selected={resultsTo}
                                        className={"margin-left-10"}
                                        onChange={(date) => updateRangeTo(date)}
                                        dateFormat="dd/MM/yyyy"
                                        closeOnScroll={true}
                                        customInput={<PeriodRangeDateButton />}
                                        maxDate={moment(simulationMaxPeriod).tz("GMT").toDate()}
                                        minDate={moment(simulationMinPeriod).tz("GMT").toDate()} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={rowStyle}>
                <Col sm={12}>
                    <span className="filter-title">Views</span>
                </Col>
                <Col sm={12}>
                    <Form.Control as="select" value={defaultView} aria-label="Period" size="sm" onChange={event=>{
                        updateView(event.target.value);
                        updateViewExplanation(event.target.value);
                    }}>
                        <optgroup label="Prices">
                            <option value="marketPrice">Market Prices</option>
                            {simulation.configuration.strategy && <option value="markups">Markups</option>}
                            <option value="priceSetterByFirm">Price Setter by Firm</option>
                            <option value="priceSetterByTechnology">Price Setter by Technology</option>
                            <option value="capturedPriceByFirm">Captured Price by Firm</option>
                            <option value="capturedPriceByTechnology">Captured Price by Technology</option>
                        </optgroup>
                        <optgroup label="Generation">
                            <option value="generationByTechnology">Generation by Technology</option>
                            <option value="generationByFirm">Generation by Firm</option>
                            <option value="generationByExcess">Excess Renewables</option>
                        </optgroup>
                        <optgroup label="Payments, Costs and Profits">
                            <option value="marginalCosts">Marginal Costs</option>
                            <option value="totalCostsByFirm">Total Costs by Firm</option>
                            <option value="totalCostsByTechnology">Total Costs by Technology</option>
                            <option value="profitsByFirm">Profits by Firm</option>
                            <option value="profitsByTechnology">Profits by Technology</option>
                            <option value="consumerPaymentsByFirm">Payments by Firm</option>
                            <option value="consumerPaymentsByTechnology">Payments by Technology</option>
                        </optgroup>
                        <optgroup label="Carbon Emissions">
                            <option value="carbonEmissionsByFirm">Carbon Emissions by Firm</option>
                            <option value="carbonEmissionsByTech">Carbon Emissions by Technology</option>
                            {/*<option value="carbonEmissionsByTotal">Carbon Emission by Total</option>*/}
                            <option value="carbonEmissionsBySummary">Carbon footprint vs emissions-free</option>
                        </optgroup>
                    </Form.Control>
                </Col>
            </Row>
            <Row style={rowStyle}>
                <Col sm={12}>
                    <span className="filter-title">Grouped By</span>
                </Col>
                <Col sm={12}>
                    <Form.Control as="select" aria-label="Period" size="sm" onChange={event=>{
                        updateAggregation(event.target.value);
                    }}>
                        {groupByOptions.map(g=><option key={g} value={g}>{groupByOptionsMapping[g]}</option>)}
                    </Form.Control>
                </Col>
            </Row>
            {compareWith}
            {compareWithSeries}
            {viewDefinition}
        </div>
    );
}

export default SimulationFilters;
