import React, {useCallback, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import SimulationConfigForm from "./SimulationConfigForm";
import SimulationResults from "./SimulationResults";

function Simulation({simulation,
                        dataSeries,
                        marketStructureList,
                        userId,
                        username,
                        deleteSimulationModal,
                        loadSimulation,
                        cancelSimulationChanges,
                        updateSimulationList,
                        updateSimulation,
                        simulationList}) {

    const [simulationData, setSimulationData] = useState(null);

    const finishSimulation = useCallback(
        (list, simulationFinished) => {
            updateSimulationList(list);
            updateSimulation({...simulationFinished});
        },
        [updateSimulationList, updateSimulation]
    );

    useEffect(() => {
        if(simulation.status === 'NEW' || simulation.status === 'RUNNING' || simulation.status === 'ERROR'){
            setSimulationData(<SimulationConfigForm simulation={simulation}
                                                      dataSeries={dataSeries}
                                                      marketStructureList={marketStructureList}
                                                      userId={userId}
                                                      username={username}
                                                      finishSimulation={finishSimulation}
                                                      updateSimulation={updateSimulation}
                                                      loadSimulation={loadSimulation}
                                                      cancelSimulationChanges={cancelSimulationChanges}
                                                      deleteSimulationModal={deleteSimulationModal}
                                                      updateSimulationList={updateSimulationList}/>);
        }else if(simulation.status === "COMPLETED"){
            setSimulationData(<SimulationResults simulation={simulation}
                                                 simulationList={simulationList}
                                                 dataSeries={dataSeries}
                                                 marketStructureList={marketStructureList}
                                                 deleteSimulationModal={deleteSimulationModal}
                                                 loadSimulation={loadSimulation}
                                                 updateSimulationList={updateSimulationList}
                                                 updateSimulation={updateSimulation}
                                                 userId={userId}
                                                 username={username}
            />)
        }
    }, [simulation, dataSeries, marketStructureList, userId,
                deleteSimulationModal, cancelSimulationChanges,
                loadSimulation, username,
                updateSimulation, updateSimulationList, finishSimulation, simulationList]);





    return (
        <Row>
            <Col sm={12}>
                {simulationData}
            </Col>
        </Row>
    );
}

export default Simulation;
