import {useForm} from "react-hook-form";
import React from "react";
import {trackPromise} from "react-promise-tracker";
import {notify} from "react-notify-bootstrap";

const SimpleUploadFile = ({userId, path, callbackOnSuccess}) => {
    const { register, handleSubmit } = useForm();

    const sendNotification = (innerHTML, variant) => {
        notify({ text: innerHTML, variant: variant });
    };

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        if(file.size > 0){
            onSubmit(file);
        }
    };

    const onSubmit = (file) => {
        let formData = new FormData();
        formData.append('file',file);
        trackPromise(
            fetch('/m40alasocho/'+ userId +'/upload/' + path, {
                method: 'POST',
                body: formData})
        )
        .then(response => response.json())
        .then(result => {
                if(result.status === 'OK'){
                    sendNotification(`File uploaded!`, "success");
                    callbackOnSuccess(result);
                }
                if(result.status === 'Fail'){
                    sendNotification(result.message, "warning");
                }
                let inputFile = document.getElementById('idUploadFile' + path);
                inputFile.value = null;
            })
            .catch(error => {
                sendNotification(`Something was wrong!`, "danger");
                console.error('Error:', error);
                let inputFile = document.getElementById('idUploadFile' + path);
                inputFile.value = null;
            }
        );
    };

    return (
        <div className="pull-right">
            <form onSubmit={handleSubmit(onSubmit)}>
                <label className="btn btn-sm btn-outline-primary float-right button-primary" style={{marginBottom: "0px"}}>
                    UPLOAD
                    <input {...register('file', { required: true })}
                           onChange={handleFileInput}
                           id={'idUploadFile'+path}
                           hidden={true}
                           type="file" name="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                </label>
            </form>
        </div>
    );
};

export default SimpleUploadFile;