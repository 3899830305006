import React from "react";
import BarChartComponent from "./BarChartComponent";


function BarChartWrapper({title, data, bars, xAxisKey, yAxisUnit, xAxisLabel, yAxisLabel}) {


    return (
            <div className="recharts-component">
                <BarChartComponent title={title} data={data} bars={bars}
                                   yAxisUnit={yAxisUnit}
                                   xAxisKey={xAxisKey}
                                   yAxisLabel={yAxisLabel}
                                   xAxisLabel={xAxisLabel}

                />
            </div>
    )
}
export default BarChartWrapper;
