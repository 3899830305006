import React, { useCallback, useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import MarketStructureLayout from "./MarketStructureLayout";
import { trackPromise } from 'react-promise-tracker';
import SimpleUploadFile from "../common/SimpleUploadFile";

import icon_market from "../../img/Icon Market Structure.png"
import bg from "../../img/Background sections-01.png"
import socket from "../common/sockets";
import {sendNotification} from "../commons";

function MarketStructure({ user, username }) {
    const [data, setData] = useState([]);
    const [marketStructure, setMarketStructure] = useState(null);
    const [socketOn, setSocketOn] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(true);
    const loadMarketStructure = useCallback(
        event => {
            event.preventDefault();
            // document.getElementsByClassName("modal-right col-sm-10")[0].style.backgroundImage = 'none';
            setBackgroundImage(false);
            setMarketStructure({
                id: event.target.id,
                detail: false
            });
        },
        []
    );

    useEffect(()=>{
        if(!socketOn && username) {
            setSocketOn(true);
            socket.on(username, function (data) {
                if (data.event === "run_simulation" && data.message === 'Simulation Done') {
                    sendNotification(data.step, "success");
                }
            });
        }
        // eslint-disable-next-line
    }, [username]);


    const onDeleteMarketStructure = (list) => {
        setData(list);
        setMarketStructure(null);
        setBackgroundImage(true);
        // document.getElementsByClassName("modal-right col-sm-10")[0].style.backgroundImage = `url(${bg})`;
    };

    const [marketStructureLayout, setMarketStructureLayout] = useState(null);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/market/structure/user/' + user.id))
            .then(res => res.json()).then(data => {
                setData(data.list);
            });
    }, [user]);


    useEffect(() => {
        if (marketStructure != null) {
            setMarketStructureLayout(<MarketStructureLayout marketId={marketStructure.id} detail={marketStructure.detail}
                onLoadAll={setMarketStructure}
                onDeleteMarketStructure={onDeleteMarketStructure} />);
        } else {
            setMarketStructureLayout(
                <Container className='filler'>
                    <Row>
                        <Col md={{ span: 9, offset: 1 }}>
                            <h1>
                                MARKET STRUCTURE
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ span: 9, offset: 1 }}>
                            <p >
                                The market structure section allows you to define and visualize all the details about the power plants in your electricity market.
                                You can visualize the energy mix and ownership structure by clicking on one of the market structure files on your left.
                                You can also upload new market structure files.
                            </p>
                        </Col>
                    </Row>
                </Container>
            );
        }
    }, [marketStructure]);

    const refreshMarketStructure = (data) => {
        setMarketStructure({
            id: data.marketStructure.id,
            detail: false
        });
        setData(data.list);
    };

    const backGroundStyle = {
        backgroundImage: backgroundImage ? `url('${bg}')` : "none"
    }

    const marketStructureList = data && data.length > 0 ? (
        <div className="text-left">
            <Alert variant="primary" className="button-primary-cover">
                <Row>
                    <Col sm={3}>
                        <img src={icon_market} alt="Market Structure" style={{ height: "40px" }}></img>
                    </Col>
                    <Col>
                        <p>
                            MARKET STRUCTURE
                        </p>
                    </Col>
                    <Col sm={3}>
                        <SimpleUploadFile userId={user.id} path="market" callbackOnSuccess={refreshMarketStructure} />
                    </Col>
                </Row>
            </Alert>
            <div className={"market-structure-fixed-list"}>
                {data.map((item, index) => {
                        return (
                            <div key={index} style={{ marginBottom: "3px !important" }}>
                                <button style={{ width: "100%" }} key={index} onClick={loadMarketStructure} id={item.id} className="btn-outline-info btn btn-sm ">
                                    {item.filename}
                                </button>
                            </div>);
                    }
                )}
            </div>
        </div>
    ) :
        <div className="text-left">
            <Alert variant="primary" className="button-primary-cover">
                <Row>
                    <Col sm={2}>
                        <img src={icon_market} alt="Simulations" style={{ height: "40px" }}></img>
                    </Col>
                    <Col>
                        <p>
                            MARKET STRUCTURE
                        </p>
                    </Col>
                    <Col sm={3}>
                        <SimpleUploadFile userId={user.id} path="market" callbackOnSuccess={refreshMarketStructure} />
                    </Col>
                </Row>
            </Alert>
        </div>;

    return (
        <Container fluid>
            <Row>
                <Col sm={2} className="modal-left">
                    <div style={{ marginTop: "10px" }}>
                        {marketStructureList}
                    </div>
                </Col>
                <Col sm={10} id={"idMarketStructureContainer"} className="modal-right" style={backGroundStyle}>
                    <div>
                        {marketStructureLayout}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default MarketStructure;
