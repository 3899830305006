import { createContext, useContext, useState } from 'react'

//create a context
const NotificationData = createContext({})
export default NotificationData

//create a provider of that context that will provide values
//which can be used by all the children conponents
export const NotificationDataProvider = ({ children }) => {
    const [notificationData, setNotificationData] = useState(null)
    return (
        <NotificationData.Provider value={{ notificationData, setNotificationData }}>
            {children}
        </NotificationData.Provider>
    )
}

//create a helper custom hook to used by other components who
//wish to use the context values
export const useNotificationDataContext = () => {
    const { notificationData, setNotificationData } = useContext(NotificationData)
    return {
        notificationData,
        setNotificationData
    }
}
