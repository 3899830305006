import { useEffect } from "react";

import { Container, Row } from "react-bootstrap";

import logo_energeia_big from "../img/Logo ENERGEIA big.png"
import bg from "../img/Background home.png"
import icon_market from "../img/Icon Market Structure.png"
import icon_data_series from "../img/Icon Data Series.png"
import icon_simulations from "../img/Icon Simulations.png"

import {useNavigate} from "react-router-dom";

function Home({user}) {

	let navigate = useNavigate();

	useEffect(() => {
		document.body.style.backgroundImage = `url('${bg}')`;
	}, []);

	const redirectMenu = (i) => {
		let path = "/market/structure";
		switch (i) {
			case 0: path="/market/structure";
				break
			case 1: path="/data/series";
				break
			case 2: path="/simulations";
				break
			case 3: path="/investments";
				break
			default: break;
		}
		navigate(path);
	}

	const investmentsCard = user.investments ?
		<div className="card-home" onClick={()=>redirectMenu(3)}>
			<div className="card-header">
				<img src={icon_simulations} alt="Investments"></img>
				<p>INVESTMENTS</p>
			</div>
			<div className="card-body">
				<p>The investments section allows you to model the market impact of low-carbon investments as well as to perform a financial assessment of those investments.</p>
			</div>

		</div>
		: null;

	return (
		<Container className='Home'>
			<Row className="d-flex justify-content-center home-logo">
				<img src={logo_energeia_big} alt="Energeia"></img>
			</Row>
			<Row>
				<p>
					How do electricity prices change when more renewable energies get deployed? By how much do carbon prices reduce the share of fossil-fuel generation? What is the impact of phasing out thermal power plants? Does it pay electricity firms to behave competitively or strategically? How do electricity prices change if two generating companies merge? What are the impacts and profitability of low-carbon investments?
					<br/>
					ENERGEIA allows you to answer these and many other questions about the performance of electricity markets and the impact of low-carbon investments. Ready to start?
				</p>
			</Row>
			<Row>
				<Container className="d-flex justify-content-center">
					<div className="card-home" onClick={()=>redirectMenu(0)}>
						<div className="card-header">
							<img src={icon_market} alt="Market Structure"></img>
							<p>MARKET STRUCTURE</p>
						</div>
						<div className="card-body">
							<p> The market structure section allows you to define and visualize all the details about the power plants in your electricity market. </p>
						</div>
					</div>

					<div className="card-home" onClick={()=>redirectMenu(1)}>
						<div className="card-header">
							<img src={icon_data_series} alt="Data Series"></img>
							<p>DATA SERIES</p>
						</div>
						<div className="card-body">
							<p>The data series section contains information about electricity demand, renewable energy availability, fossil-fuel prices, and interconnection prices in your electricity market.</p>
						</div>

					</div>
					<div className="card-home" onClick={()=>redirectMenu(2)}>
						<div className="card-header">
							<img src={icon_simulations} alt="Simulations"></img>
							<p>SIMULATIONS</p>
						</div>
						<div className="card-body">
							<p>The simulations section allows you to define the timeframe and assumptions for your simulations, run them, visualize the outcomes and compare them. </p>
						</div>

					</div>

					{investmentsCard}

				</Container>
			</Row>
		</Container>
	)
};

export default Home;
