import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import BarChartWrapper from "../common/BarChartWrapper";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

/**
 * @return {null}
 */
function CompanyCapacity({data}) {
    let companies = [];
    data.forEach(m => {
        if(!companies.includes(m.company)){
            return companies.push(m.company);
        }
    });

    let companiesInfo = {};
    let technologyData = [];
    companies.forEach(c=>{
        technologyData.push(data.filter(m=>m.company === c).reduce((result, filter) => {
            result["name"] = c;
            result[filter.technology] = filter.power;
            companiesInfo[filter.technology] = filter.technology;
            return result;
        },{}));
    });


    return data && data.length > 0 ? (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <div className="recharts-component">
                <BarChartWrapper title="Capacity by Firms"
                                 yAxisUnit={"MW"}
                                 data={technologyData} bars={companiesInfo} xAxisKey="name"/>
            </div>
        </ErrorBoundary>
    ) : null;
}

export default CompanyCapacity;
