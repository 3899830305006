import React from "react";
import {Col, Row} from "react-bootstrap";


function SimulationInfo({simulation, callbackLoadSimulation}) {

    const loadSimulation = (e) => {
        e.preventDefault();
        const simulationId = e.target.id;
        callbackLoadSimulation(simulationId);
    };

    const clazzName = (simulation.status === 'NEW' || simulation.status === 'RUNNING' ||
        simulation.status === 'ERROR') ?
        'btn btn-sm btn-outline-success' : 'btn-outline-info btn btn-sm ';
    const dateInfo = (simulation.status === 'NEW' || simulation.status === 'RUNNING' ||
        simulation.status === 'ERROR')
        ? ("Creation date " + simulation.creation_date.join(" "))
        : ("Simulation date " + simulation.simulation_date.join(" "));

    return (
        <Row style={{marginBottom: "5px"}}>
            <Col sm={12}>
                <button title={dateInfo} style={{width: "100%"}} key={simulation.id} onClick={loadSimulation} id={simulation.id} className={clazzName}>
                    {simulation.name}
                </button>
            </Col>
        </Row>

    );
}

export default SimulationInfo;
