const reportWebVitals = onPerfEntry => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import('web-vitals').then(({ onLCP, onINP, onCLS }) => {
            onLCP(onPerfEntry);
            onINP(onPerfEntry);
            onCLS(onPerfEntry);
        });
    }
};

export default reportWebVitals;
