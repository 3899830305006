import React, {useState} from "react";
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from '../../../node_modules/react-plotly.js/react-plotly';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

const PlotlyRenderers = createPlotlyRenderers(Plot);


/**
 * @return {null}
 */
function PivotTable({data}) {
    const [pivotUpdate, setPivotUpdate] = useState(null);

    let pivotData = [['Firm', 'Technology', 'power']];
    data.forEach(d=>{
       pivotData.push([d.company, d.technology, parseFloat(d.power)]);
    });

    return data && data.length > 0 ? (
        <div>
            <h6>Firms and Technologies</h6>
            <PivotTableUI
                data={pivotData}
                rendererName="Table Heatmap"
                aggregatorName="Integer Sum"
                vals={["power"]}
                hiddenFromDragDrop={["power"]}
                plotlyOptions={{"width": "100%", "height": 450}}
                onChange={s => setPivotUpdate(s)}
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                {...pivotUpdate}
            />
          <small>Please drag the labels "Technology" and "Firms" to the row or to the columns boxes to display the table. You can also select the format of the table.</small>
        </div>) : null;
}

export default PivotTable;
