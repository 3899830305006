import React, {useCallback, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import InvestmentConfigForm from "./InvestmentConfigForm";
import InvestmentResults from "./InvestmentResults";

function Investment({investment,
                        dataSeries,
                        marketStructureList,
                        userId,
                        username,
                        deleteSimulationModal,
                        loadInvestment,
                        cancelSimulationChanges,
                        updateInvestmentList,
                        updateInvestment,
                        simulationList}) {

    const [investmentData, setInvestmentData] = useState(null);

    const finishSimulation = useCallback(
        (list, simulationFinished) => {
            updateInvestmentList(list);
            updateInvestment({...simulationFinished});
        },
        [updateInvestmentList, updateInvestment]
    );

    useEffect(() => {
        if(investment.status === 'NEW' || investment.status === 'RUNNING' || investment.status === 'ERROR'){
            setInvestmentData(<InvestmentConfigForm investment={investment}
                                                      dataSeries={dataSeries}
                                                      marketStructureList={marketStructureList}
                                                      userId={userId}
                                                      username={username}
                                                      finishSimulation={finishSimulation}
                                                      updateInvestment={updateInvestment}
                                                      loadInvestment={loadInvestment}
                                                      cancelSimulationChanges={cancelSimulationChanges}
                                                      deleteSimulationModal={deleteSimulationModal}
                                                      updateInvestmentList={updateInvestmentList}
            />);
        }else if(investment.status === "COMPLETED"){
            setInvestmentData(<InvestmentResults simulation={investment}
                                                 simulationList={simulationList}
                                                 dataSeries={dataSeries}
                                                 marketStructureList={marketStructureList}
                                                 deleteSimulationModal={deleteSimulationModal}
                                                 loadSimulation={loadInvestment}
                                                 updateSimulationList={updateInvestmentList}
                                                 updateSimulation={updateInvestment}
                                                 userId={userId}
                                                 username={username}
            />)
        }
    }, [investment, dataSeries, marketStructureList, userId,
                loadInvestment, cancelSimulationChanges, deleteSimulationModal,
                username, updateInvestment, updateInvestmentList, finishSimulation, simulationList]);





    return (
        <Row>
            <Col sm={12}>
                {investmentData}
            </Col>
        </Row>
    );
}

export default Investment;
