import {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import {Table} from "react-bootstrap";
import {getFormattedNumber} from "../../commons";

function RegulatorHydrogenSummary({
                              viewName,
                              typeName,
                              aggregation,
                              simulationId,
                              simulationInitialValues,
                              simulation
                          }){

    const [regulatorData, setRegulatorData] = useState(null);

    useEffect(() => {
        if(simulationId && simulationInitialValues && typeName) {
            trackPromise(
                fetch('/m40alasocho/investment/' + simulationId + '/settings/' + simulationInitialValues + '/results/' + typeName + '?'
                    + 'aggregation=' + aggregation))
                .then(res => res.json()).then(data => {
                if (data["data"].length === 1) {
                    setRegulatorData({...JSON.parse(data["data"][0]["data"])});
                }
            });
        }
    }, [simulationId, aggregation, typeName, simulationInitialValues]);


    let summaryInfo = null;
    if (regulatorData && viewName === "Regulator's view - Summary"){
        summaryInfo = (
            <div>
                <fieldset>
                    <legend className={"text-left"}>
                        Project's metrics
                    </legend>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Total H2 production (tons)</th>
                            <th>Excess electricity production (Avg per year)</th>
                            <th>NPV of regulator revenue (M€)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{getFormattedNumber(regulatorData['Total H2 production (tons)'], 2)}</td>
                            <td>{regulatorData['Average yearly excess (MWh)']}</td>
                            <td>{getFormattedNumber(regulatorData['NPV of regulator revenue']/1000000, 0)}</td>
                        </tr>
                        </tbody>
                    </Table>
                </fieldset>
            </div>
        )
    }

    if (regulatorData && viewName === "Investor's view - Summary"){
        summaryInfo = (
            <fieldset>
                <legend className={"text-left"}>
                    Project's metrics
                </legend>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>LCOH (€/MWh)</th>
                        <th>Payback date</th>
                        <th>Payback period (years)</th>
                        <th>Enterprise NPV (M€)</th>
                        <th>Equity NPV (M€)</th>
                        <th>IRR</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{getFormattedNumber(regulatorData['LCOE (€/MWh)'], 2)}</td>
                        <td>{regulatorData['Payback date']}</td>
                        <td>{regulatorData['Payback period']}</td>
                        <td>{getFormattedNumber(regulatorData['Enterprise NPV']/1000000, 2)}</td>
                        <td>{getFormattedNumber(regulatorData['Equity NPV']/1000000, 2)}</td>
                        <td>{getFormattedNumber(regulatorData['IRR'], 2)}</td>
                    </tr>
                    </tbody>
                </Table>
            </fieldset>
        )
    }

    return regulatorData ? (
        <div>
            <h3>{viewName}</h3>
            {summaryInfo}
        </div>
    ) : <h3>Loading...</h3>
}

export default RegulatorHydrogenSummary;