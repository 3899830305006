import React from "react";
import {Alert, Col, Row} from "react-bootstrap";
import SimpleUploadFile from "../common/SimpleUploadFile";
import {getIconByDataType} from "../commons";

function DataTypeItem({dataType, loadDataTypeCallback, refreshDataTypesCallback, user}) {

    const loadData = (e) => {
        e.preventDefault();
        const dataFileId = e.target.id;
        loadDataTypeCallback(dataFileId, dataType);
    };

    const refreshDataTypes = (data) => {
        refreshDataTypesCallback(data);
    };

    let dataTypeTitle = getIconByDataType(dataType);

    return (
        <div className="data-types-list text-left">
            <Alert variant="primary" className="button-secondary-cover">
                <Row>
                    <Col sm={9} id="icon">
                        {dataTypeTitle}
                    </Col>
                    <Col sm={3}>
                        <SimpleUploadFile userId={user.id} path={'data/series/' + dataType.type} callbackOnSuccess={refreshDataTypes}/>
                    </Col>
                </Row>
            </Alert>
            <div className="data-types-files">
                {dataType.files.length > 0 ? dataType.files.map((file, index)=> {
                        return (<div className="data-series-file" key={index}>
                                    <button key={index} onClick={loadData} id={file.id} className="btn btn-sm btn-outline-info" style={{width: "100%"}}>
                                        {file.name} {file.is_default ? ' (default)' : ''}
                                    </button>
                                </div>);
                    }
                ) :
                    <Alert variant="warning">
                        <Row>
                            <Col sm={12}>No Files updated of type {dataTypeTitle}</Col>
                        </Row>
                    </Alert>
                }
            </div>
        </div>
    );
}

export default DataTypeItem;
