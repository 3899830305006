import React, {useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import LineChartWrapper from "../common/LineChartWrapper";
import {buildColumnsFromData, convertToMomentTz, getDateFormatByAggregation} from "../commons";
import BasicTable from "../common/BasicTable";
import ChartTableToggle from "../common/ChartTableToggle";
import {Col, Row} from "react-bootstrap";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

function FuelDataChart({data, all}) {

    const [tabMode, setTabMode] = useState("charts");

    const tableData = [...all].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation("Hour"))}));

    const dataByMode = tabMode === "charts" ? (
        <div>
            <LineChartWrapper data={data.map(d => ({"time": d.time, "gas" : +d.gas}))} title="Gas" brushKey="time" xAxisKey="time" yAxisUnit="€/MWht" />
            <LineChartWrapper data={data.map(d => ({"time": d.time, "co2" : +d.co2}))} title="CO2" brushKey="time" xAxisKey="time" yAxisUnit="€/Tn" />
            <LineChartWrapper data={data.map(d => ({"time": d.time, "coal" : +d.coal}))} title="Coal" brushKey="time" xAxisKey="time" yAxisUnit="€/MWht" />
            <LineChartWrapper data={data.map(d => ({"time": d.time, "tax" : +d.tax}))} title="Tax" brushKey="time" xAxisKey="time" yAxisUnit="%" decimals={2}/>
            <LineChartWrapper data={data.map(d => ({"time": d.time, "hydrogen" : +d.hydrogen}))} title="Hydrogen" brushKey="time" xAxisKey="time" yAxisUnit="€/MWht" />
        </div>
    ) : (
        <div style={{marginTop: "15px"}}>
            <BasicTable title={"Fuel Production"} data={tableData} columns={buildColumnsFromData(tableData)} options={{
                fixedHeaderScrollHeight: "350px"
            }}/>;
        </div>
    );


    return data && data.length > 0 ? (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <div className="recharts-component">
                <ChartTableToggle mode={tabMode} updateTabMode={setTabMode} updateSelectedTab={()=>{}}/>
                <Row>
                    <Col sm={12}>
                        {dataByMode}
                    </Col>
                </Row>
            </div>
        </ErrorBoundary>
    ) : <h1>No data found</h1>;
}

export default FuelDataChart;
