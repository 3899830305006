import React, {useEffect, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {trackPromise} from "react-promise-tracker";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import LineChartWrapper from "../common/LineChartWrapper";
import {
    buildComparativeData,
    buildRangeParameters,
    convertToMomentTz,
    getDateFormatByAggregation,
    sendNotification
} from "../commons";
import ChartTableToggle from "../common/ChartTableToggle";
import TablePagination from "../common/TablePagination";
import BasicTable from "../common/BasicTable";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

const priceColumns = [
    {
        name: "Date",
        selector: row => row.time
    },
    {
        name: "Max Price (%)",
        selector: row => row.maxPrice
    },
    {
        name: "Min Price (%)",
        selector: row => row.minPrice
    }
];

function MarkupOutput({simulationId, range, simulationList, compareWith, aggregation}) {

    const [prices, setPrices] = useState([]);
    const [compareSimulations, setCompareSimulations] = useState([]);
    const [tabMode, setTabMode] = useState("charts");
    const [tabSelected, setTabSelected] = useState(1);


    useEffect(()=>{

    }, [tabMode, tabSelected]);

    const isStrategy = (simId) => {
        return simulationList.filter(s => s.id === parseInt(simId) && s.strategy).length === 1;
    }

    useEffect(() => {
        if(compareWith.length > 0) {
            compareWith.forEach(c => {
                if(compareSimulations.filter(cs=>cs["simId"]===c).length === 0) {
                    if(isStrategy(c)) {
                        trackPromise(
                            fetch('/m40alasocho/simulation/' + c + '/results/markup?' + buildRangeParameters(range) + '&aggregation=' + aggregation))
                            .then(res => res.json()).then(data => {
                                setCompareSimulations(comp => [...comp, {
                                    "data": [...data["data"].map(d => {
                                        let out = {...d}
                                        out["time"] = d["entryDate"];
                                        delete out.entryDate;
                                        return out;
                                    })],
                                    "simId": c
                                }
                            ]);
                        });
                    }
                    else{
                        sendNotification("Simulation " + c + " does not contain the strategic equilibrium. Markups are not displayed", "warning");
                    }
                }
            });
            setCompareSimulations(cs => cs.filter(c=>compareWith.includes(c["simId"])));
        }else{
            setCompareSimulations([]);
        }
        // eslint-disable-next-line
    }, [range, compareWith, aggregation]);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationId + '/results/markup?' + buildRangeParameters(range) + '&aggregation='+ aggregation))
            .then(res => res.json()).then(data => {
            setPrices(data["data"]);
        });
    }, [simulationId, range, aggregation]);

    const data = prices && prices.map(p=>{
        let d = {
            ...p,
            "time": p.entryDate
        };
        delete d.entryDate;
        return d;
    });

    const tableData = [...data].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(aggregation))}));
    const tableUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/markup?' + buildRangeParameters(range) + '&aggregation=' + aggregation;
    const pricesTable = aggregation === 'Hour' && prices && prices.length > 0 ? (
        <TablePagination title="Markup (Cronological)" url={tableUrl} columns={priceColumns} options={{
            fixedHeaderScrollHeight: "350px"
        }}/>
    ) : <BasicTable title={"Markup (Cronological)"} data={tableData} columns={priceColumns} options={{
        fixedHeaderScrollHeight: "350px"
    }}/>;

    const chartData = compareSimulations.length > 0 ? buildComparativeData(compareSimulations, data) : data;
    const tabsByMode = tabMode === "charts" ? (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological ">
                <LineChartWrapper data={chartData}
                                  aggregation={aggregation}
                                  title="MarkUp " brushKey="time" xAxisKey="time" yAxisUnit="%" />
            </Tab>
            <Tab eventKey={2} title="Duration ">
                <LineChartWrapper data={chartData}
                                  aggregation={aggregation}
                                  title="MarkUp " brushKey="time" xAxisKey="index" yAxisUnit="%" />
            </Tab>
        </Tabs>
    ) : (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological ">
                <div style={{marginTop: "15px"}}>
                    {pricesTable}
                </div>
            </Tab>
        </Tabs>
    );

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <div className="recharts-component">
                <ChartTableToggle mode={tabMode} updateSelectedTab={setTabSelected} updateTabMode={setTabMode}/>
                <Row>
                    <Col sm={12}>
                        {tabsByMode}
                    </Col>
                </Row>
            </div>
        </ErrorBoundary>
    );
}

export default MarkupOutput;
