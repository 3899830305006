import {
    BiEuro,
    BiWater,
    BiWorld
} from "react-icons/bi";
import {
    FaGasPump,
    FaRecycle
} from "react-icons/fa";
import {
    GiElectric
} from "react-icons/gi";
import {
    GrHostMaintenance,
    GrTechnology
} from "react-icons/gr";
import React from "react";
import {notify} from "react-notify-bootstrap";
import moment from "moment-timezone";

const numericUnits = ["€/MWh", "€/M", "Ton/MW", "Ton"]

const technologiesOrder = ["Exp", "Storage demand", "Nuclear", "Hydro", "Coal", "CCGT",
"Storage supply", "Wind", "Solar Thermal", "Solar PV", "Other renewables", "Cogen", "Imp"]


const technologiesColors = {
    renewableco: "#CFA2CA",
    renewableother: "#9A5CBC",
    renewablest: "#6FB114",
    renewablepv: "#A32225",
    hydro: "#0090D1",
    ccgt: "#E38501",
    coal: "#993300",
    nuclear: "#464394",
    renewablew: "#6FB114",
    ea: "#E6DDB7",
    wind: "#13CA2B",
    "solar pv": "#dab600",
    "solar thermal": "#fff9ae",
    "other renewables": "#b20000",
    cogen: "#8614d8",
    storage: "#10AE9E",
    "imp/exp": "#297BC3",
    "imp": "#30119A",
    "exp": "#297BC3",
    "storage demand": "#89da9d",
    "storage supply": "#13CA2B",
    "nat": "#A32225"
}

export const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#82009d",
    "#20009d",
    "#E39225",
    "#16CB5A",
    "#3173C4",
    "#e9d700",
    "#687483",
    "#12A283",
    "#30889A",
    "#12CB2A",
    "#3123A4",
    "#247243",
    "#12C483",
    "#10889E",
    "#8614d8",
    "#89da9d",
    "#12000d",
    "#20339d",
    "#A32225",
    "#36CA4A",
    "#2190C4",
    "#AA7483",
    "#19A213",
    "#30119A",
    "#13CA2B",
    "#4133C4",
    "#297BC3",
    "#21C4AC",
    "#10AE9E"];

export function generateRandomColor() {
    return '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
}

export const noDataAvailableStyle = {
    marginTop: "15%",
    color: "red"
};

export const orderKeys = (dict) => {
    return Object.keys(dict).sort().reduce(
        (obj, key) => {
            obj[key] = dict[key];
            return obj;
        },
        {}
    );
}

export const findColorByKey = (key, idx) =>{
    key = key.replace("-", "").toLowerCase();
    if(key in technologiesColors)
        return technologiesColors[key];
    if(idx < COLORS.length)
        return COLORS[idx];
    else
        return generateRandomColor()
}

export const buildDateFromStr = (yyyyMMdd) => {
    let dateOn = yyyyMMdd.split("-");
    return new Date(parseInt(dateOn[0]), dateOn[1] - 1, parseInt(dateOn[2]));
}

export const deleteInterConnections = (out, keysToRemove) => {
    keysToRemove.forEach(i=>{
        if(i in out){
            delete out[i];
        }
    })
    return out;
}

export const formatDateFromString = (dateString, format) => {
    if(dateString){
        if(format){
            try {
                let yourDate = new Date(dateString);
                return yourDate.toISOString().split('T')[0];
            }catch (error){
                return dateString;
            }
        }
        return dateString;
    }
    return dateString;
}
export const getIconByDataType = (dataType) => {
    if(dataType) {
        let dataTypeTitle = dataType.type;
        switch (dataType.type) {
            case "fuel":
                dataTypeTitle = <span id="icon"><FaGasPump/> Fuel</span>;
                break;
            case "hydro":
                dataTypeTitle = <span id="icon"><BiWater/> Hydro</span>;
                break;
            case "price":
                dataTypeTitle = <span id="icon"><BiEuro/> Electricity Prices</span>;
                break;
            case "demand":
                dataTypeTitle = <span id="icon"><GiElectric/> Demand</span>;
                break;
            case "renewable":
                dataTypeTitle = <span id="icon"><FaRecycle/> Renewable</span>;
                break;
            case "interconnection":
                dataTypeTitle = <span id="icon"><BiWorld/> Interconnections</span>;
                break;
            case "maintenance":
                dataTypeTitle = <span id="icon"><GrHostMaintenance/> Maintenance</span>;
                break;
            case "technology_generation":
                dataTypeTitle = <span id="icon"><GrTechnology/> Generation by Technology</span>;
                break;
            default:
                dataTypeTitle = dataType.type;
        }
        return dataTypeTitle;
    }
    return null;
};

function getTotalByRow(row){
    let total = 0;
    Object.keys(row).forEach(k=>{
        if(k !== "time" && k !== "Time"){
            total += parseFloat(row[k]);
        }
    })
    return total;
}

export const addTotals = (data) => {
    if(data.length > 0){
        return data.map((d)=> {
            return {...d, "Total": getTotalByRow(d)};
        });
    }
    return data;
}

export const addTotalsVertical = (data) => {
    if(data.length > 0){
        const keys = Object.keys(data[0]);
        let totalRow = {
            "time": "Total"
        }
        keys.forEach(k=>{
           if(k !== 'time'){
               let total = 0;
               data.forEach(d=>{
                   total += d[k];
               });
               totalRow[k] = total;
           }
        });
        data.push(totalRow);
    }
    return data;
}
export const sendNotification = (innerHTML, variant) => {
    notify({ text: innerHTML, variant: variant });
};

export const processMessage = (data) => {
    if(data.event === 'run_simulation' && (data.method === 'Simulation Done' ||
                                           data.method.includes('Simulation error'))){
        sendNotification(data.message + " " + data.method, data.status === "Success" ? "success" : "warning");
    }
}

export const getDomainByLocation = () => {
    const protocol = window.location.protocol;
    const domain = window.location.host;
    return protocol + "//" + domain;
};

export const getWSDomainByLocation = () => {
    const domain = window.location.host;
    if(domain.includes("localhost")){
        return "ws://localhost:5000/";
    }
    return `wss://${window.location.host}/`;
};


export function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

export const buildRangeParameters = (range) =>{
    return "from=" + convertDateToStr(range[0]) + (range[1] ? ("&to=" + convertDateToStr(range[1])) : "");
}


export const filterByStrategy = (compareSimulations, strategy) =>{

    return compareSimulations.map(k=>{
        return {
            ...k,
            "data": k["data"].filter(d => d["strategy"] === strategy).map(g => {
                if("data" in g) {
                    return {
                        time: g["entryDate"],
                        ...JSON.parse(g["data"])
                    }
                }else{
                    let out = {
                        ...g
                    }
                    if("entryDate" in g){
                        out["time"] = g["entryDate"];
                    }
                    delete out["entryDate"]
                    return out
                }
            })}
            ;
    });
}

export const buildComparativeData = (comparativeSimulations, data) =>{
    let compareData = [];
    if(comparativeSimulations.length > 0){
        comparativeSimulations.forEach(k=> {
            compareData = compareData.concat(k["data"].map(d => {
                let point;
                point = {...d};
                let simulationsData = {};
                Object.keys(point).forEach(a => {
                    if(a !== 'time' && a !== 'strategy') {
                        if ("simId" in k) {
                            simulationsData[a + k["simId"]] = point[a];
                        } else {
                            simulationsData[a + k["ds"]] = point[a];
                        }
                    }
                });
                return {
                    time: d["time"],
                    ...simulationsData
                }
            }));
        });
        compareData = compareData.concat(data);
    }
    const result = compareData.sort((a, b) => a.time - b.time);
    const uniqueTimes = [...new Set(result.map(item => item.time))];
    let groupedCompared = [];
    uniqueTimes.forEach(t => {
       const points = result.filter(d => d.time === t);
       let p = {};
       p["time"] = t;
       points.forEach(pt => {
            Object.keys(pt).forEach(k=>{
                if(k !== 'time'){
                    p[k] = pt[k];
                }
            })
       });
       groupedCompared.push(p);
    });
    return groupedCompared;
}


export const processItemToJson = (g) => {
    let out = {};
    if("data" in g) {
        out = {
            time: g["entryDate"],
            ...JSON.parse(g["data"])
        }
    }else{
        out = {
            ...g,
            "time": g["entryDate"]
        }
        delete out["entryDate"]
    }
    //convert 0's to NaN or undefined
    Object.keys(out).forEach(k=>{
       if(k !== "time" && out[k] === 0){
           out[k] = undefined;
       }
    });
    return out
}

export const buildColumnsFromData = (data) =>{
    let columns = [];
    if(data.length > 0){
        const d = data[0];
        let timeColumn = null;
        Object.keys(d).forEach(k=>{
            let cellProps = {
                name: k,
                sortable: true,
                wrap: true,
                selector: row => row[k]
            };
            if(k === "time" && d[k]){
                cellProps["width"] = "170px";
                cellProps["name"] = "Time";
                timeColumn = cellProps;
            }else if(k.startsWith("Renewable")){
                cellProps["width"] = "160px";
            }
            if(k !== "time") {
                columns.push(cellProps);
            }
        });
        if(timeColumn) {
            columns.unshift(timeColumn);
        }
    }
    return columns;
}

export const removeFromArray = (arr, item) =>
{
    const index = arr.indexOf(item);
    return [
        ...arr.slice(0, index),
        ...arr.slice(index + 1)
    ];
}

export const convertDateToStr = (d) =>{
    const date = new Date(d);
    return moment(date).tz("GMT").format("DD-MM-YYYY");
}

export const  buildSeries = (data, series) => {
    let keys = Object.keys(data[0]).map(k => k);
    const size = data.length;
    keys.forEach((f, idx)=>{
        const sortedData = [...data].map(d=>{
            return {
                [f]: d[f] ? d[f] : 0
            }
        }).sort(function(a, b){
            return b[f] - a[f]
        }).map((d, idx)=> {
            return {...d, index: ((idx + 1) / size) * 100}
        });
        series.push({
            name: f,
            color: findColorByKey(f, idx),
            data: sortedData
        });
    });
}

export const buildDataAggregated = (data, series, aggData) => {
    if(data.length > 0 && series.length > 0) {
        let keys = Object.keys(data[0]).filter(f => f !== "time").map(k => k);
        if(series.filter(f => f.name === keys[0]).length > 0
            && "data" in series.filter(f => f.name === keys[0])[0]) {
            series.filter(f => f.name === keys[0])[0]["data"].forEach((d, idx) => {
                aggData[idx] = {
                    "index": d["index"]
                };
                keys.forEach(r => {
                    aggData[idx] = {
                        ...aggData[idx],
                        [r]: series.filter(s => s.name === r)[0]["data"][idx][r]
                    }
                })
            })
        }
    }
}

export const getLineVisibility = (val, entry) =>{
    if (entry["payload"]["visibility"] === 'hidden'){
        return <span style={{ opacity:0.3, textDecoration:"line-through" }}>{val}</span>;
    }
    const { color } = entry;
    return <span style={{ color }}>{val}</span>;
}


/**
 *
 * @param data
 * @param aggregation by Firm or Technology
 * @returns {{}}
 */
export const sortTechnologies = (data, aggregation) => {
    if(aggregation === "Firm"){
        return data;
    }
    let keys = Object.keys(data);
    let out = {}
    technologiesOrder.forEach(t=>{
        if(keys.includes(t)){
            out[t] = data[t];
        }
    });
    keys.filter(k => !technologiesOrder.includes(k)).forEach(k=>{
        out[k] = data[k]
    });
    return out;
}
export const getFormattedValue = (props, value, decimals) => {
    if(props["unit"] && numericUnits.includes(props["unit"])){
        return new Intl.NumberFormat('en-EN', {
            maximumFractionDigits: decimals === 0 ? 0 : decimals,
        }).format(value);
    }
    if(!isNaN(value)){
        return new Intl.NumberFormat('en-EN', {
            maximumFractionDigits: decimals ? decimals : 2,
        }).format(value);
    }
    return value;
}

export const formatNumbersDecimals = (data) => {
    return data.map(d=>{
            let out = {}
            Object.keys(d).forEach(k=>{
                if(k !== 'time'){
                    out[k] = getFormattedNumber(d[k], 2);
                }else{
                    out[k] = d[k];
                }
            });
            return out;
        });
}

export const getFormattedNumber = ( value, decimals) => {
    if(!isNaN(value)){
        return new Intl.NumberFormat('en-EN', {
            maximumFractionDigits: decimals ? decimals : 1,
        }).format(value);
    }
    return value;
}

export const divideUnitsBy = (data, divideBy) => {
    return data.map(d=>{
        let result = {};
        Object.keys(d).forEach(k=>{
            if(k === "time"){
                result[k] = d[k]
            }else{
                result[k] = d[k]/divideBy;
            }
        })
        return result;
    });
}

export const getLabelByAggregation = (val, aggregation) => {
    const tz = "GMT";
    if(aggregation === 'YearInt' || aggregation === "label"){
        return val;
    }
    if(aggregation === 'MonthEpoch'){
        return moment(val).format('MMM-YYYY');
    }
    if(aggregation === 'Date'){
        return moment.unix(val).tz(tz).format('DD-MM-YYYY');
    }else if(aggregation === 'Month'){
        return moment.unix(val).tz(tz).format('MMM-YYYY');
    }else if(aggregation === 'Year'){
        return moment.unix(val).tz(tz).format('YYYY');
    }
    return moment.unix(val).tz(tz).format('DD-MM-YYYY HH:mm');
}

export const getDateFormatByAggregation = (aggregation) => {
    if(aggregation === 'Date'){
        return 'DD-MM-YYYY';
    }else if(aggregation === 'Month'){
        return 'MMM-YYYY';
    }else if(aggregation === 'Year' || aggregation === 'All'){
        return 'YYYY';
    }
    return 'DD-MM-YYYY HH:mm';
}

export const convertToUTC = (dateString) => {
    const estDate = new Date(dateString);
    const offset = estDate.getTimezoneOffset();
    const utcDate = new Date(estDate.getTime() + (offset * 60 * 1000));
    utcDate.setUTCHours(0);
    return utcDate;
}

export const convertToMomentTz = (time) => {
    return moment.unix(time).tz("GMT");
}
