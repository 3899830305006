import React, {useEffect, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {trackPromise} from "react-promise-tracker";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import LineChartWrapper from "../common/LineChartWrapper";
import AreaChartWrapper from "../common/AreaChartWrapper";
import {
    addTotals,
    buildColumnsFromData,
    buildComparativeData,
    buildRangeParameters,
    convertToMomentTz,
    deleteInterConnections,
    filterByStrategy,
    getDateFormatByAggregation,
    sendNotification,
    sortTechnologies
} from "../commons";
import ChartTableToggle from "../common/ChartTableToggle";
import TablePagination from "../common/TablePagination";
import BasicTable from "../common/BasicTable";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

function filterTechnologies(data) {
    return data.map(d=>{
        return {
            time: d["time"],
            Coal: d["Coal"],
            CCGT: d["CCGT"],
            Cogen: d["Cogen"]
        }
    });
}

function filterTechnologiesKeepStrategy(data) {
    return data.map(d=>{
        return {
            time: d["time"],
            Coal: d["Coal"],
            CCGT: d["CCGT"],
            Cogen: d["Cogen"],
            strategy: d["strategy"]
        }
    });
}

function processEmissionData(groupBy, g, aggregation) {
    if (groupBy !== 'All' && "data" in g) {
        let data = sortTechnologies(JSON.parse(g["data"]), aggregation);
        let out = {
            time: g["entryDate"],
            ...data
        };
        deleteInterConnections(out, ["MAR", "POR", "FRA", "Exp", "Imp", "Exp/Imp"]);
        return out;
    } else {
        let out = {...g}
        delete out.strategy;
        let data = sortTechnologies(out, aggregation);
        deleteInterConnections(data, ["MAR", "POR", "FRA", "Exp", "Imp", "Exp/Imp"]);
        return data;
    }
}

function clearNotRequiredKeys(data) {
    if(data && data.length > 0) {
        return data.map(d => {
            let out = {};
            Object.keys(d).forEach(k => {
                if (!k.startsWith("Total Emissions (Ton)")) {
                    out[k] = d[k];
                }
            });
            return out;
        });
    }
    return [];
}

function EmissionOutput({simulationId, aggregation, range, compareWith,
    groupBy, strategy}) {

    const [prices, setPrices] = useState([]);
    const [compareSimulations, setCompareSimulations] = useState([]);
    const [tabMode, setTabMode] = useState("charts");
    const [tabSelected, setTabSelected] = useState(1);

    useEffect(()=>{

    }, [tabMode, tabSelected]);

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationId + '/results/emission'
                + aggregation +'?' + buildRangeParameters(range)
                + '&aggregation='+ groupBy
                + '&allData=' + ((compareWith.length > 0) ? '1' : '0')))
            .then(res => res.json()).then(data => {
            setPrices(data["data"]);
        });
    }, [simulationId, aggregation, range, groupBy, compareWith]);

    useEffect(() => {
        if(compareWith.length > 0) {
            if(aggregation === 'Summary' || groupBy === 'All') {
                compareWith.forEach(c => {
                    trackPromise(
                        fetch('/m40alasocho/simulation/' + c + '/results/emission'
                            + aggregation + '?' + buildRangeParameters(range)
                            + '&aggregation=' + groupBy
                            + '&allData=' + ((compareWith.length > 0) ? '1'
                                : '0')))
                    .then(res => res.json()).then(data => {
                        if (compareSimulations.filter(
                            cs => cs["simId"] === c).length === 0) {
                            let simData = [...data["data"]].map(d=>{
                                let out = {...d};
                                delete out['Total Emissions (Ton)']
                                return out;
                            })
                            if(aggregation === 'Technology') {
                                simData = filterTechnologiesKeepStrategy(simData);
                            }
                            setCompareSimulations(comp => [...comp, {
                                "data": simData,
                                "simId": c
                            }]);
                        } else {
                            setCompareSimulations(comp => comp.map(cs => {
                                if (cs["simId"] !== c) {
                                    return cs;
                                } else {
                                    let simData = [...data["data"]].map(d=>{
                                        let out = {...d};
                                        delete out['Total Emissions (Ton)']
                                        return out;
                                    });
                                    if(aggregation === 'Technology') {
                                        simData = filterTechnologiesKeepStrategy(simData);
                                    }
                                    return {
                                        ...cs,
                                        "data": simData
                                    }
                                }
                            }));
                        }
                    });
                });
                setCompareSimulations(
                    cs => cs.filter(c => compareWith.includes(c["simId"])));
            }
            else{
                sendNotification("This view can only be compared under Simulation Period", "info");
                setCompareSimulations([]);
            }
        }else{
            setCompareSimulations([]);
        }
        // eslint-disable-next-line
    }, [aggregation, range, compareWith, groupBy]);


    let byStrat = prices.filter(g=>g["strategy"]).map(g=> {
        return processEmissionData(groupBy, g, aggregation);
    });
    let byComp = prices.filter(g=>!g["strategy"]).map(g=> {
        return processEmissionData(groupBy, g, aggregation);
    });

    byComp.forEach(i=>{
        delete i['Total Emissions (Ton)']
    });
    byStrat.forEach(i=>{
        delete i['Total Emissions (Ton)']
    });
    if(byComp && byComp.length > 0 && aggregation === 'Technology'){
        byComp = filterTechnologies(byComp);
    }
    if(byStrat && byStrat.length > 0 && aggregation === 'Technology'){
        byStrat = filterTechnologies(byStrat);
    }

    let chartDataStrategic = [], chartDataCompetitive = [];
    let yAxisKeys = [];

    if(byStrat.length > 0) {
        chartDataStrategic = compareSimulations.length > 0 ? clearNotRequiredKeys(buildComparativeData(filterByStrategy(compareSimulations, true), byStrat)) : byStrat;
    }
    if(byComp.length > 0) {
        chartDataCompetitive = compareSimulations.length > 0 ? clearNotRequiredKeys(buildComparativeData(filterByStrategy(compareSimulations, false), byComp)) : byComp;
        yAxisKeys = Object.keys(chartDataCompetitive[0]).filter(k => k.startsWith("Emissions-free %"));
    }

    const tableUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/emission' + aggregation + '?' + buildRangeParameters(range) + '&aggregation=' + groupBy + '&mode=strategic';
    const dataTableStrat =[...chartDataStrategic].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))}));
    const tableStrategic = groupBy === 'Hour' && chartDataStrategic && chartDataStrategic.length > 0 ? (
        <TablePagination title={"Chronological (Strategic)"} columns={buildColumnsFromData(chartDataStrategic)} url={tableUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) : <BasicTable title={"Chronological (Strategic)"} data={aggregation !== "Summary" ? addTotals(dataTableStrat) : dataTableStrat}  columns={aggregation !== "Summary" ? buildColumnsFromData(addTotals(dataTableStrat)) : buildColumnsFromData(chartDataStrategic)} options={{
        fixedHeaderScrollHeight: "350px"
    }}/>;

    const tableCompetitiveUrl = '/m40alasocho/simulation/' + simulationId + '/pagination/emission' + aggregation + '?' + buildRangeParameters(range) + '&aggregation=' + groupBy + '&mode=competitive';
    const dataTableComp =[...chartDataCompetitive].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))}));
    const tableCompetitive = groupBy === 'Hour' && chartDataCompetitive && chartDataCompetitive.length > 0 ? (
        <TablePagination title={"Chronological (Competitive)"} columns={buildColumnsFromData(chartDataCompetitive)} url={tableCompetitiveUrl}
                         options={{
                             fixedHeaderScrollHeight: "350px"
                         }}/>
    ) : <BasicTable title={"Chronological (Competitive)"} data={aggregation !== "Summary" ? addTotals(dataTableComp) : dataTableComp} columns={aggregation !== "Summary" ? buildColumnsFromData(addTotals(dataTableComp)) : buildColumnsFromData(chartDataCompetitive)} options={{
        fixedHeaderScrollHeight: "350px"
    }}/>;

    let tabStrategicVsCompetitive = null;
    if(['Year', 'All'].includes(groupBy) && strategy && chartDataCompetitive.length > 0){
        let aggregatedObject = {}
        Object.keys(chartDataCompetitive[0]).forEach(k => {
            if(k !== 'time'){
                aggregatedObject[k + '[C]'] = chartDataCompetitive[0][k];
            }
        });
        Object.keys(chartDataStrategic[0]).forEach(k => {
            if(k !== 'time') {
                aggregatedObject[k + '[S]'] = chartDataStrategic[0][k];
            }
        });

        tabStrategicVsCompetitive = <Tab eventKey={5} title="Competitive vs Strategic">
            <AreaChartWrapper data={[aggregatedObject]}
                              title={"Competitive vs Strategic"}
                              aggregation={groupBy}
                              xAxisKey="index" yAxisUnit="M€"/>
        </Tab>
    }

    const chartTabs = aggregation === "Summary" ? (
        <div className="recharts-component">
            <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" className="mb-3" transition={false} onSelect={(tab)=>{
                setTabSelected(tab)
            }}>
                <Tab eventKey={1} title="Chronological (Competitive) ">
                    <LineChartWrapper data={chartDataCompetitive}
                                      title={'Carbon footprint vs emissions-free (Competitive)'}
                                      decimals={2}
                                      aggregation={groupBy}
                                        brushKey="time" xAxisKey="time" yAxisUnit="Ton/MWh" yAxisRight={{
                                                                                                        "unit": "%",
                                                                                                        "label": "Emissions-free %",
                                                                                                        "keys": yAxisKeys
                                                                                                    }}/>
                </Tab>
                <Tab eventKey={2} title="Duration (Competitive)">
                    <LineChartWrapper data={chartDataCompetitive} title={'Carbon footprint vs emissions-free (Competitive)'}
                                      aggregation={groupBy}
                                      decimals={2}
                                      brushKey="time" xAxisKey="index" yAxisUnit="Ton/MWh" yAxisRight={{
                                                                                            "unit": "%",
                                                                                            "label": "Emissions-free %",
                                                                                            "keys": yAxisKeys
                                                                                        }}/>
                </Tab>
                {strategy &&
                    <Tab eventKey={3} title="Chronological (Strategic) ">
                        <LineChartWrapper data={chartDataStrategic}
                                          title={'Carbon footprint vs emissions-free (Strategic)'}
                                          aggregation={groupBy}
                                          decimals={2}
                                          brushKey="time" xAxisKey="time"
                                          yAxisUnit="Ton/MWh" yAxisRight={{
                                                                "unit": "%",
                                                                "label": "Emissions-free %",
                                                                "keys": yAxisKeys
                                                            }}/>
                    </Tab>
                }
                {strategy &&
                    <Tab eventKey={4} title="Duration (Strategic)">
                        <LineChartWrapper data={chartDataStrategic}
                                          decimals={2}
                                          title={'Carbon footprint vs emissions-free (Strategic)'}
                                          aggregation={groupBy}
                                          brushKey="time" xAxisKey="index"
                                          yAxisUnit="Ton/MWh" yAxisRight={{
                                                                        "unit": "%",
                                                                        "label": "Emissions-free %",
                                                                        "keys": yAxisKeys
                                                                    }}/>
                    </Tab>
                }
                {tabStrategicVsCompetitive}
            </Tabs>
        </div>
            ) : (
        <div className="recharts-component">
            <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" className="mb-3" transition={false} onSelect={(tab)=>{
                setTabSelected(tab)
            }}>
                <Tab eventKey={1} title="Chronological (Competitive) ">
                    <AreaChartWrapper data={chartDataCompetitive} title={"Emissions by " + aggregation +" (Competitive)"}
                                      aggregation={groupBy}
                                      xAxisKey="time" brushKey="time" yAxisUnit="Ton" />
                </Tab>
                <Tab eventKey={2} title="Duration (Competitive)">
                    <LineChartWrapper data={chartDataCompetitive}
                                      aggregation={groupBy}
                                      title={'Emissions By ' + aggregation + " (Competitive)" } brushKey="time" xAxisKey="index" yAxisUnit="Ton" />
                </Tab>
                {strategy &&
                    <Tab eventKey={3} title="Chronological (Strategic) ">
                        <AreaChartWrapper data={chartDataStrategic}
                                          title={"Emissions by " + aggregation
                                              + " (Strategic)"}
                                          aggregation={groupBy}
                                          xAxisKey="time" brushKey="time"
                                          yAxisUnit="€/MWh"/>
                    </Tab>
                }
                {strategy &&
                    <Tab eventKey={4} title="Duration (Strategic)">
                        <LineChartWrapper data={chartDataStrategic}
                                          aggregation={groupBy}
                                          title={'Emissions By ' + aggregation
                                              + " (Strategic)"} brushKey="time"
                                          xAxisKey="index" yAxisUnit="Ton"/>
                    </Tab>
                }
                {tabStrategicVsCompetitive}
            </Tabs>
        </div>
    );

    const tabsByMode = tabMode === "charts" ? (
        chartTabs
    ) : (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive) ">
                {tableCompetitive}
            </Tab>
            {strategy &&
                <Tab eventKey={2} title="Chronological (Strategic) ">
                    {tableStrategic}
                </Tab>
            }
        </Tabs>
    );

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <div>
                <ChartTableToggle mode={tabMode} updateSelectedTab={setTabSelected} updateTabMode={setTabMode}/>
                <Row>
                    <Col sm={12}>
                        {tabsByMode}
                    </Col>
                </Row>
            </div>
        </ErrorBoundary>
    );
}

export default EmissionOutput;
